@charset "UTF-8";
.passport h1 {
  font-family: 'Helvetica Neue LT W01_93 BlkEx', Helvetica, sans-serif;
  text-transform: uppercase;
  font-size: 26px;
  font-style: italic;
  margin: 0px 0px 20px 0px; }

.ru-ru .passport h1,
.pl-pl .passport h1,
.hu-hu .passport h1,
.cs-cz .passport h1,
.et-ee .passport h1,
.lv-lv .passport h1,
.lt-lt .passport h1,
[lang^='ru'] .passport h1,
[lang^='pl'] .passport h1,
[lang^='hu'] .passport h1,
[lang^='cs'] .passport h1,
[lang^='et'] .passport h1,
[lang^='lv'] .passport h1,
[lang^='lt'] .passport h1 {
  font-weight: bold;
  font-family: 'Arimo', sans-serif !important; }

.ru-ru .passport h1 strong,
.pl-pl .passport h1 strong,
.hu-hu .passport h1 strong,
.cs-cz .passport h1 strong,
.et-ee .passport h1 strong,
.lv-lv .passport h1 strong,
.lt-lt .passport h1 strong,
[lang^='ru'] .passport h1 strong,
[lang^='pl'] .passport h1 strong,
[lang^='hu'] .passport h1 strong,
[lang^='cs'] .passport h1 strong,
[lang^='et'] .passport h1 strong,
[lang^='lv'] .passport h1 strong,
[lang^='lt'] .passport h1 strong {
  font-weight: bold;
  font-family: 'Arimo', sans-serif !important; }

.ru-ru .passport h1 b,
.pl-pl .passport h1 b,
.hu-hu .passport h1 b,
.cs-cz .passport h1 b,
.et-ee .passport h1 b,
.lv-lv .passport h1 b,
.lt-lt .passport h1 b,
[lang^='ru'] .passport h1 b,
[lang^='pl'] .passport h1 b,
[lang^='hu'] .passport h1 b,
[lang^='cs'] .passport h1 b,
[lang^='et'] .passport h1 b,
[lang^='lv'] .passport h1 b,
[lang^='lt'] .passport h1 b {
  font-weight: bold;
  font-family: 'Arimo', sans-serif !important; }

.passport h2 {
  font-family: 'Helvetica Neue LT W05_83 Hv Ex', Helvetica, sans-serif;
  text-transform: uppercase;
  font-size: 16px;
  margin: 0px 0px 20px 0px; }

.ru-ru .passport h2,
.pl-pl .passport h2,
.hu-hu .passport h2,
.cs-cz .passport h2,
.et-ee .passport h2,
.lv-lv .passport h2,
.lt-lt .passport h2,
[lang^='ru'] .passport h2,
[lang^='pl'] .passport h2,
[lang^='hu'] .passport h2,
[lang^='cs'] .passport h2,
[lang^='et'] .passport h2,
[lang^='lv'] .passport h2,
[lang^='lt'] .passport h2 {
  font-weight: bold;
  font-family: 'Arimo', sans-serif !important; }

.ru-ru .passport h2 strong,
.pl-pl .passport h2 strong,
.hu-hu .passport h2 strong,
.cs-cz .passport h2 strong,
.et-ee .passport h2 strong,
.lv-lv .passport h2 strong,
.lt-lt .passport h2 strong,
[lang^='ru'] .passport h2 strong,
[lang^='pl'] .passport h2 strong,
[lang^='hu'] .passport h2 strong,
[lang^='cs'] .passport h2 strong,
[lang^='et'] .passport h2 strong,
[lang^='lv'] .passport h2 strong,
[lang^='lt'] .passport h2 strong {
  font-weight: bold;
  font-family: 'Arimo', sans-serif !important; }

.ru-ru .passport h2 b,
.pl-pl .passport h2 b,
.hu-hu .passport h2 b,
.cs-cz .passport h2 b,
.et-ee .passport h2 b,
.lv-lv .passport h2 b,
.lt-lt .passport h2 b,
[lang^='ru'] .passport h2 b,
[lang^='pl'] .passport h2 b,
[lang^='hu'] .passport h2 b,
[lang^='cs'] .passport h2 b,
[lang^='et'] .passport h2 b,
[lang^='lv'] .passport h2 b,
[lang^='lt'] .passport h2 b {
  font-weight: bold;
  font-family: 'Arimo', sans-serif !important; }

.passport h3,
.passport h4,
.passport h5 {
  font-family: 'Helvetica Neue LT W05_83 Hv Ex', Helvetica, sans-serif;
  font-size: 15px;
  margin: 0px 0px 20px 0px;
  text-transform: uppercase; }

.ru-ru .passport h3,
.pl-pl .passport h3,
.hu-hu .passport h3,
.cs-cz .passport h3,
.et-ee .passport h3,
.lv-lv .passport h3,
.lt-lt .passport h3,
[lang^='ru'] .passport h3,
[lang^='pl'] .passport h3,
[lang^='hu'] .passport h3,
[lang^='cs'] .passport h3,
[lang^='et'] .passport h3,
[lang^='lv'] .passport h3,
[lang^='lt'] .passport h3, .ru-ru
.passport h4,
.pl-pl
.passport h4,
.hu-hu
.passport h4,
.cs-cz
.passport h4,
.et-ee
.passport h4,
.lv-lv
.passport h4,
.lt-lt
.passport h4,
[lang^='ru']
.passport h4,
[lang^='pl']
.passport h4,
[lang^='hu']
.passport h4,
[lang^='cs']
.passport h4,
[lang^='et']
.passport h4,
[lang^='lv']
.passport h4,
[lang^='lt']
.passport h4, .ru-ru
.passport h5,
.pl-pl
.passport h5,
.hu-hu
.passport h5,
.cs-cz
.passport h5,
.et-ee
.passport h5,
.lv-lv
.passport h5,
.lt-lt
.passport h5,
[lang^='ru']
.passport h5,
[lang^='pl']
.passport h5,
[lang^='hu']
.passport h5,
[lang^='cs']
.passport h5,
[lang^='et']
.passport h5,
[lang^='lv']
.passport h5,
[lang^='lt']
.passport h5 {
  font-weight: bold;
  font-family: 'Arimo', sans-serif !important; }

.ru-ru .passport h3 strong,
.pl-pl .passport h3 strong,
.hu-hu .passport h3 strong,
.cs-cz .passport h3 strong,
.et-ee .passport h3 strong,
.lv-lv .passport h3 strong,
.lt-lt .passport h3 strong,
[lang^='ru'] .passport h3 strong,
[lang^='pl'] .passport h3 strong,
[lang^='hu'] .passport h3 strong,
[lang^='cs'] .passport h3 strong,
[lang^='et'] .passport h3 strong,
[lang^='lv'] .passport h3 strong,
[lang^='lt'] .passport h3 strong, .ru-ru
.passport h4 strong,
.pl-pl
.passport h4 strong,
.hu-hu
.passport h4 strong,
.cs-cz
.passport h4 strong,
.et-ee
.passport h4 strong,
.lv-lv
.passport h4 strong,
.lt-lt
.passport h4 strong,
[lang^='ru']
.passport h4 strong,
[lang^='pl']
.passport h4 strong,
[lang^='hu']
.passport h4 strong,
[lang^='cs']
.passport h4 strong,
[lang^='et']
.passport h4 strong,
[lang^='lv']
.passport h4 strong,
[lang^='lt']
.passport h4 strong, .ru-ru
.passport h5 strong,
.pl-pl
.passport h5 strong,
.hu-hu
.passport h5 strong,
.cs-cz
.passport h5 strong,
.et-ee
.passport h5 strong,
.lv-lv
.passport h5 strong,
.lt-lt
.passport h5 strong,
[lang^='ru']
.passport h5 strong,
[lang^='pl']
.passport h5 strong,
[lang^='hu']
.passport h5 strong,
[lang^='cs']
.passport h5 strong,
[lang^='et']
.passport h5 strong,
[lang^='lv']
.passport h5 strong,
[lang^='lt']
.passport h5 strong {
  font-weight: bold;
  font-family: 'Arimo', sans-serif !important; }

.ru-ru .passport h3 b,
.pl-pl .passport h3 b,
.hu-hu .passport h3 b,
.cs-cz .passport h3 b,
.et-ee .passport h3 b,
.lv-lv .passport h3 b,
.lt-lt .passport h3 b,
[lang^='ru'] .passport h3 b,
[lang^='pl'] .passport h3 b,
[lang^='hu'] .passport h3 b,
[lang^='cs'] .passport h3 b,
[lang^='et'] .passport h3 b,
[lang^='lv'] .passport h3 b,
[lang^='lt'] .passport h3 b, .ru-ru
.passport h4 b,
.pl-pl
.passport h4 b,
.hu-hu
.passport h4 b,
.cs-cz
.passport h4 b,
.et-ee
.passport h4 b,
.lv-lv
.passport h4 b,
.lt-lt
.passport h4 b,
[lang^='ru']
.passport h4 b,
[lang^='pl']
.passport h4 b,
[lang^='hu']
.passport h4 b,
[lang^='cs']
.passport h4 b,
[lang^='et']
.passport h4 b,
[lang^='lv']
.passport h4 b,
[lang^='lt']
.passport h4 b, .ru-ru
.passport h5 b,
.pl-pl
.passport h5 b,
.hu-hu
.passport h5 b,
.cs-cz
.passport h5 b,
.et-ee
.passport h5 b,
.lv-lv
.passport h5 b,
.lt-lt
.passport h5 b,
[lang^='ru']
.passport h5 b,
[lang^='pl']
.passport h5 b,
[lang^='hu']
.passport h5 b,
[lang^='cs']
.passport h5 b,
[lang^='et']
.passport h5 b,
[lang^='lv']
.passport h5 b,
[lang^='lt']
.passport h5 b {
  font-weight: bold;
  font-family: 'Arimo', sans-serif !important; }

.passport p {
  margin: 0px 0px 20px 0px; }

.passport p,
.passport body {
  font-family: 'Helvetica Neue LT W01_55 Roman', Helvetica, sans-serif;
  font-size: 14px; }

.ru-ru .passport p,
.pl-pl .passport p,
.hu-hu .passport p,
.cs-cz .passport p,
.et-ee .passport p,
.lv-lv .passport p,
.lt-lt .passport p,
[lang^='ru'] .passport p,
[lang^='pl'] .passport p,
[lang^='hu'] .passport p,
[lang^='cs'] .passport p,
[lang^='et'] .passport p,
[lang^='lv'] .passport p,
[lang^='lt'] .passport p, .ru-ru
.passport body,
.pl-pl
.passport body,
.hu-hu
.passport body,
.cs-cz
.passport body,
.et-ee
.passport body,
.lv-lv
.passport body,
.lt-lt
.passport body,
[lang^='ru']
.passport body,
[lang^='pl']
.passport body,
[lang^='hu']
.passport body,
[lang^='cs']
.passport body,
[lang^='et']
.passport body,
[lang^='lv']
.passport body,
[lang^='lt']
.passport body {
  font-weight: bold;
  font-family: 'Arimo', sans-serif !important; }

.ru-ru .passport p strong,
.pl-pl .passport p strong,
.hu-hu .passport p strong,
.cs-cz .passport p strong,
.et-ee .passport p strong,
.lv-lv .passport p strong,
.lt-lt .passport p strong,
[lang^='ru'] .passport p strong,
[lang^='pl'] .passport p strong,
[lang^='hu'] .passport p strong,
[lang^='cs'] .passport p strong,
[lang^='et'] .passport p strong,
[lang^='lv'] .passport p strong,
[lang^='lt'] .passport p strong, .ru-ru
.passport body strong,
.pl-pl
.passport body strong,
.hu-hu
.passport body strong,
.cs-cz
.passport body strong,
.et-ee
.passport body strong,
.lv-lv
.passport body strong,
.lt-lt
.passport body strong,
[lang^='ru']
.passport body strong,
[lang^='pl']
.passport body strong,
[lang^='hu']
.passport body strong,
[lang^='cs']
.passport body strong,
[lang^='et']
.passport body strong,
[lang^='lv']
.passport body strong,
[lang^='lt']
.passport body strong {
  font-weight: bold;
  font-family: 'Arimo', sans-serif !important; }

.ru-ru .passport p b,
.pl-pl .passport p b,
.hu-hu .passport p b,
.cs-cz .passport p b,
.et-ee .passport p b,
.lv-lv .passport p b,
.lt-lt .passport p b,
[lang^='ru'] .passport p b,
[lang^='pl'] .passport p b,
[lang^='hu'] .passport p b,
[lang^='cs'] .passport p b,
[lang^='et'] .passport p b,
[lang^='lv'] .passport p b,
[lang^='lt'] .passport p b, .ru-ru
.passport body b,
.pl-pl
.passport body b,
.hu-hu
.passport body b,
.cs-cz
.passport body b,
.et-ee
.passport body b,
.lv-lv
.passport body b,
.lt-lt
.passport body b,
[lang^='ru']
.passport body b,
[lang^='pl']
.passport body b,
[lang^='hu']
.passport body b,
[lang^='cs']
.passport body b,
[lang^='et']
.passport body b,
[lang^='lv']
.passport body b,
[lang^='lt']
.passport body b {
  font-weight: bold;
  font-family: 'Arimo', sans-serif !important; }

.passport .align--left {
  text-align: left; }

.passport .align--right {
  text-align: right; }

.passport .align--center {
  text-align: center; }

.passport-form {
  width: 100%;
  margin: 0 auto;
  position: relative;
  min-width: 300px; }

@media (min-width: 768px) {
  .passport-form {
    width: 475px;
    min-width: auto; } }

.passport-form__close {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 0;
  right: 0;
  color: #fff;
  background-color: #db011c;
  z-index: 1;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.passport-form__close svg {
  width: 100%;
  height: 100%; }

.passport-form__inner {
  padding: 30px;
  background-color: #fff; }

.passport-form__inner--confirmation {
  border-bottom: 4px solid #db011c; }

.passport-form__inner .confirmation {
  text-align: center; }

.passport-form__inner .confirmation__title {
  margin-top: 10px; }

.passport-form__inner .confirmation__text {
  padding: 0 30px; }

@media (min-width: 768px) {
  .passport-form__inner .confirmation__text {
    padding: 0 60px; } }

.passport-form__row {
  width: 100%;
  position: relative;
  display: inline-block;
  margin-top: 20px;
  margin-bottom: 10px; }

.passport-form__row p.error {
  margin-top: 15px; }

.passport-form__label {
  position: absolute;
  left: 20px;
  top: 0;
  display: block;
  margin: 0;
  font-size: 11px;
  line-height: 20px;
  opacity: 1;
  -webkit-transform: translateY(-100%);
          transform: translateY(-100%);
  -webkit-transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  z-index: 1;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.passport-form__control:not([type='checkbox']):not([type='radio']):placeholder-shown + .passport-form__label {
  opacity: 0;
  -webkit-transform: translateY(0);
          transform: translateY(0); }

.passport-form__control:not([type='checkbox']):not([type='radio']):not(:placeholder-shown) + .passport-form__label {
  opacity: 1;
  -webkit-transform: translateY(-100%);
          transform: translateY(-100%); }

.passport-form__control {
  width: 100%;
  position: relative;
  display: block;
  height: 40px;
  padding: 10px 20px 9px;
  border: 0;
  border-bottom: 2px solid #231f20;
  background: #fff;
  box-shadow: none;
  font-size: 14px;
  letter-spacing: 1px;
  line-height: 20px;
  outline: 0;
  -webkit-transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  z-index: 2; }

.passport-form__control .passport-form__control:focus {
  background: #fbfbfb;
  box-shadow: none;
  outline: 0; }

.invalid .passport-form__control {
  border-bottom-color: #db011c; }

.invalid .passport-form__control::-webkit-input-placeholder {
  color: #db011c; }

.invalid .passport-form__control::-moz-placeholder {
  color: #db011c; }

.invalid .passport-form__control:-ms-input-placeholder {
  color: #db011c; }

.invalid .passport-form__control::-ms-input-placeholder {
  color: #db011c; }

.invalid .passport-form__control::placeholder {
  color: #db011c; }

input[type='checkbox'] .passport-form__control,
input[type='radio'] .passport-form__control {
  display: none; }

.passport-form__control .passport-form__select-wrapper {
  position: relative; }

.passport-form__control .passport-form__select-wrapper:after {
  content: '';
  right: 10px;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }

select .passport-form__control {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; }

textarea .passport-form__control {
  height: auto;
  padding: 10px 19px 9px;
  border: 1px solid fadeout(#323232, 50%);
  border-radius: 5px;
  min-height: 120px; }

.invalid textarea .passport-form__control {
  border-color: #db011c; }

input[type='text']:-moz-read-only .passport-form__control {
  cursor: not-allowed;
  -moz-user-select: none;
       user-select: none;
  color: fade(#000, 50%); }

input[type='text']:read-only .passport-form__control {
  cursor: not-allowed;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  color: fade(#000, 50%); }

.passport-form__dropdown {
  cursor: pointer;
  z-index: 3; }

.passport-form__dropdown-values {
  display: none;
  background-color: #fbfbfb;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  z-index: 2;
  max-height: 280px;
  overflow-y: auto; }

.passport-form__dropdown-selected {
  position: relative;
  z-index: 1; }

.invalid .passport-form__dropdown-selected {
  color: #db011c; }

.passport-form__dropdown-selected:after {
  content: '';
  z-index: 2;
  top: 50%;
  right: 0px;
  width: 12px;
  height: 6px;
  position: absolute;
  background-image: url("data:image/svg;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMiIgaGVpZ2h0PSI2IiB2aWV3Qm94PSIwIDAgMTIgNiI+PHBhdGggZD0iTTAsMEgxMkw2LDZaIi8+PC9zdmc+");
  background-size: contain;
  background-repeat: no-repeat; }

.passport-form__dropdown-value {
  padding: 10px 20px 9px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.passport-form__dropdown-value:hover {
  background-color: #f4f4f4; }

.passport-form__dropdown.active {
  background-color: #fbfbfb;
  z-index: 5; }

.passport-form__dropdown.active .passport-form__dropdown-values {
  display: block; }

.passport-form__checkbox {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.passport-form__checkbox.active:before {
  background-image: url("data:image/svg;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMCIgaGVpZ2h0PSIxMCIgdmlld0JveD0iMCAwIDEwIDEwIj48cGF0aCBkPSJNMi43MzQsMy45MzIsNC40NTMsNS42NTEsOS4yMTkuODg1LDEwLDEuNjY3LDQuNDUzLDcuMjE0bC0yLjUtMi41Wk04Ljg4LDguODhWNC40NTNIMTBWOC44OEExLjE1OCwxLjE1OCwwLDAsMSw4Ljg4LDEwSDEuMTJhMS4wNzMsMS4wNzMsMCwwLDEtLjc4MS0uMzM5QTEuMDczLDEuMDczLDAsMCwxLDAsOC44OFYxLjEyQTEuMDczLDEuMDczLDAsMCwxLC4zMzkuMzM5LDEuMDczLDEuMDczLDAsMCwxLDEuMTIsMEg2LjY2N1YxLjEySDEuMTJWOC44OFpNMTAsMTAiIGZpbGw9IiMzMjMyMzIiLz48L3N2Zz4="); }

.passport-form__checkbox:before {
  content: '';
  position: relative;
  width: 20px;
  height: 20px;
  margin-right: 10px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-image: url("data:image/svg;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMCIgaGVpZ2h0PSIxMCIgdmlld0JveD0iMCAwIDEwIDEwIj48ZyBmaWxsPSJub25lIiBzdHJva2U9IiMzMjMyMzIiIHN0cm9rZS13aWR0aD0iMSI+PHJlY3Qgd2lkdGg9IjEwIiBoZWlnaHQ9IjEwIiByeD0iMiIgc3Ryb2tlPSJub25lIi8+PHJlY3QgeD0iMC41IiB5PSIwLjUiIHdpZHRoPSI5IiBoZWlnaHQ9IjkiIHJ4PSIxLjUiIGZpbGw9Im5vbmUiLz48L2c+PC9zdmc+"); }

.passport-form__forgotten-password {
  font-family: 'Helvetica Neue LT W01_55 Roman', Helvetica, sans-serif;
  margin: 5px 0;
  font-size: 13px; }

.ru-ru .passport-form__forgotten-password,
.pl-pl .passport-form__forgotten-password,
.hu-hu .passport-form__forgotten-password,
.cs-cz .passport-form__forgotten-password,
.et-ee .passport-form__forgotten-password,
.lv-lv .passport-form__forgotten-password,
.lt-lt .passport-form__forgotten-password,
[lang^='ru'] .passport-form__forgotten-password,
[lang^='pl'] .passport-form__forgotten-password,
[lang^='hu'] .passport-form__forgotten-password,
[lang^='cs'] .passport-form__forgotten-password,
[lang^='et'] .passport-form__forgotten-password,
[lang^='lv'] .passport-form__forgotten-password,
[lang^='lt'] .passport-form__forgotten-password {
  font-weight: bold;
  font-family: 'Arimo', sans-serif !important; }

.ru-ru .passport-form__forgotten-password strong,
.pl-pl .passport-form__forgotten-password strong,
.hu-hu .passport-form__forgotten-password strong,
.cs-cz .passport-form__forgotten-password strong,
.et-ee .passport-form__forgotten-password strong,
.lv-lv .passport-form__forgotten-password strong,
.lt-lt .passport-form__forgotten-password strong,
[lang^='ru'] .passport-form__forgotten-password strong,
[lang^='pl'] .passport-form__forgotten-password strong,
[lang^='hu'] .passport-form__forgotten-password strong,
[lang^='cs'] .passport-form__forgotten-password strong,
[lang^='et'] .passport-form__forgotten-password strong,
[lang^='lv'] .passport-form__forgotten-password strong,
[lang^='lt'] .passport-form__forgotten-password strong {
  font-weight: bold;
  font-family: 'Arimo', sans-serif !important; }

.ru-ru .passport-form__forgotten-password b,
.pl-pl .passport-form__forgotten-password b,
.hu-hu .passport-form__forgotten-password b,
.cs-cz .passport-form__forgotten-password b,
.et-ee .passport-form__forgotten-password b,
.lv-lv .passport-form__forgotten-password b,
.lt-lt .passport-form__forgotten-password b,
[lang^='ru'] .passport-form__forgotten-password b,
[lang^='pl'] .passport-form__forgotten-password b,
[lang^='hu'] .passport-form__forgotten-password b,
[lang^='cs'] .passport-form__forgotten-password b,
[lang^='et'] .passport-form__forgotten-password b,
[lang^='lv'] .passport-form__forgotten-password b,
[lang^='lt'] .passport-form__forgotten-password b {
  font-weight: bold;
  font-family: 'Arimo', sans-serif !important; }

.passport-form__forgotten-password a {
  font-family: 'Helvetica Neue LT W05_63 Md Ex', sans-serif;
  font-style: normal;
  font-weight: normal;
  color: #db011c;
  margin-left: 5px; }

.ru-ru .passport-form__forgotten-password a,
.pl-pl .passport-form__forgotten-password a,
.hu-hu .passport-form__forgotten-password a,
.cs-cz .passport-form__forgotten-password a,
.et-ee .passport-form__forgotten-password a,
.lv-lv .passport-form__forgotten-password a,
.lt-lt .passport-form__forgotten-password a,
[lang^='ru'] .passport-form__forgotten-password a,
[lang^='pl'] .passport-form__forgotten-password a,
[lang^='hu'] .passport-form__forgotten-password a,
[lang^='cs'] .passport-form__forgotten-password a,
[lang^='et'] .passport-form__forgotten-password a,
[lang^='lv'] .passport-form__forgotten-password a,
[lang^='lt'] .passport-form__forgotten-password a {
  font-weight: bold;
  font-family: 'Arimo', sans-serif !important; }

.ru-ru .passport-form__forgotten-password a strong,
.pl-pl .passport-form__forgotten-password a strong,
.hu-hu .passport-form__forgotten-password a strong,
.cs-cz .passport-form__forgotten-password a strong,
.et-ee .passport-form__forgotten-password a strong,
.lv-lv .passport-form__forgotten-password a strong,
.lt-lt .passport-form__forgotten-password a strong,
[lang^='ru'] .passport-form__forgotten-password a strong,
[lang^='pl'] .passport-form__forgotten-password a strong,
[lang^='hu'] .passport-form__forgotten-password a strong,
[lang^='cs'] .passport-form__forgotten-password a strong,
[lang^='et'] .passport-form__forgotten-password a strong,
[lang^='lv'] .passport-form__forgotten-password a strong,
[lang^='lt'] .passport-form__forgotten-password a strong {
  font-weight: bold;
  font-family: 'Arimo', sans-serif !important; }

.ru-ru .passport-form__forgotten-password a b,
.pl-pl .passport-form__forgotten-password a b,
.hu-hu .passport-form__forgotten-password a b,
.cs-cz .passport-form__forgotten-password a b,
.et-ee .passport-form__forgotten-password a b,
.lv-lv .passport-form__forgotten-password a b,
.lt-lt .passport-form__forgotten-password a b,
[lang^='ru'] .passport-form__forgotten-password a b,
[lang^='pl'] .passport-form__forgotten-password a b,
[lang^='hu'] .passport-form__forgotten-password a b,
[lang^='cs'] .passport-form__forgotten-password a b,
[lang^='et'] .passport-form__forgotten-password a b,
[lang^='lv'] .passport-form__forgotten-password a b,
[lang^='lt'] .passport-form__forgotten-password a b {
  font-weight: bold;
  font-family: 'Arimo', sans-serif !important; }

.passport-loader {
  width: 40px;
  height: 40px;
  margin: 100px auto;
  background-color: #db011c;
  border-radius: 100%;
  -webkit-animation: sk-scaleout 1.6s infinite ease-in-out;
          animation: sk-scaleout 1.6s infinite ease-in-out; }

@-webkit-keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0); }
  100% {
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
    opacity: 0; } }

@keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0); }
  100% {
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
    opacity: 0; } }

.passport-btn {
  font-family: 'Helvetica Neue LT W05_83 Hv Ex', Helvetica, sans-serif;
  text-transform: uppercase;
  font-size: 16px;
  padding: 14px 20px;
  color: #fff;
  background-color: #db011c;
  display: block;
  width: 100%;
  text-decoration: none; }

.ru-ru .passport-btn,
.pl-pl .passport-btn,
.hu-hu .passport-btn,
.cs-cz .passport-btn,
.et-ee .passport-btn,
.lv-lv .passport-btn,
.lt-lt .passport-btn,
[lang^='ru'] .passport-btn,
[lang^='pl'] .passport-btn,
[lang^='hu'] .passport-btn,
[lang^='cs'] .passport-btn,
[lang^='et'] .passport-btn,
[lang^='lv'] .passport-btn,
[lang^='lt'] .passport-btn {
  font-weight: bold;
  font-family: 'Arimo', sans-serif !important; }

.ru-ru .passport-btn strong,
.pl-pl .passport-btn strong,
.hu-hu .passport-btn strong,
.cs-cz .passport-btn strong,
.et-ee .passport-btn strong,
.lv-lv .passport-btn strong,
.lt-lt .passport-btn strong,
[lang^='ru'] .passport-btn strong,
[lang^='pl'] .passport-btn strong,
[lang^='hu'] .passport-btn strong,
[lang^='cs'] .passport-btn strong,
[lang^='et'] .passport-btn strong,
[lang^='lv'] .passport-btn strong,
[lang^='lt'] .passport-btn strong {
  font-weight: bold;
  font-family: 'Arimo', sans-serif !important; }

.ru-ru .passport-btn b,
.pl-pl .passport-btn b,
.hu-hu .passport-btn b,
.cs-cz .passport-btn b,
.et-ee .passport-btn b,
.lv-lv .passport-btn b,
.lt-lt .passport-btn b,
[lang^='ru'] .passport-btn b,
[lang^='pl'] .passport-btn b,
[lang^='hu'] .passport-btn b,
[lang^='cs'] .passport-btn b,
[lang^='et'] .passport-btn b,
[lang^='lv'] .passport-btn b,
[lang^='lt'] .passport-btn b {
  font-weight: bold;
  font-family: 'Arimo', sans-serif !important; }

.tabs__create .passport-btn {
  font-size: 14px;
  padding: 8px 15px; }

.passport-btn--link {
  font-family: 'Helvetica Neue LT W01_55 Roman', Helvetica, sans-serif;
  font-style: normal;
  font-weight: normal;
  color: #db011c;
  margin-left: 5px;
  padding: 0;
  font-size: 14px;
  background-color: transparent;
  text-decoration: underline;
  text-transform: none;
  display: inline;
  width: auto;
  margin: 0; }

.ru-ru .passport-btn--link,
.pl-pl .passport-btn--link,
.hu-hu .passport-btn--link,
.cs-cz .passport-btn--link,
.et-ee .passport-btn--link,
.lv-lv .passport-btn--link,
.lt-lt .passport-btn--link,
[lang^='ru'] .passport-btn--link,
[lang^='pl'] .passport-btn--link,
[lang^='hu'] .passport-btn--link,
[lang^='cs'] .passport-btn--link,
[lang^='et'] .passport-btn--link,
[lang^='lv'] .passport-btn--link,
[lang^='lt'] .passport-btn--link {
  font-weight: bold;
  font-family: 'Arimo', sans-serif !important; }

.ru-ru .passport-btn--link strong,
.pl-pl .passport-btn--link strong,
.hu-hu .passport-btn--link strong,
.cs-cz .passport-btn--link strong,
.et-ee .passport-btn--link strong,
.lv-lv .passport-btn--link strong,
.lt-lt .passport-btn--link strong,
[lang^='ru'] .passport-btn--link strong,
[lang^='pl'] .passport-btn--link strong,
[lang^='hu'] .passport-btn--link strong,
[lang^='cs'] .passport-btn--link strong,
[lang^='et'] .passport-btn--link strong,
[lang^='lv'] .passport-btn--link strong,
[lang^='lt'] .passport-btn--link strong {
  font-weight: bold;
  font-family: 'Arimo', sans-serif !important; }

.ru-ru .passport-btn--link b,
.pl-pl .passport-btn--link b,
.hu-hu .passport-btn--link b,
.cs-cz .passport-btn--link b,
.et-ee .passport-btn--link b,
.lv-lv .passport-btn--link b,
.lt-lt .passport-btn--link b,
[lang^='ru'] .passport-btn--link b,
[lang^='pl'] .passport-btn--link b,
[lang^='hu'] .passport-btn--link b,
[lang^='cs'] .passport-btn--link b,
[lang^='et'] .passport-btn--link b,
[lang^='lv'] .passport-btn--link b,
[lang^='lt'] .passport-btn--link b {
  font-weight: bold;
  font-family: 'Arimo', sans-serif !important; }

.passport-btn--inline {
  display: inline-block;
  width: auto; }

.passport-btn--black {
  background-color: #000; }

.passport-btn--dark-grey {
  background-color: #383739; }

.passport-btn--grey {
  background-color: #b5b5b5; }

.passport-btn--grey:active, .passport-btn--grey.active {
  background-color: #db011c; }

.passport-btn--disabled {
  cursor: not-allowed;
  background-color: #b5b5b5; }

.passport-btn--disabled:active, .passport-btn--disabled.active {
  background-color: #b5b5b5; }

.passport-main {
  padding: 0px;
  width: 100%;
  background-color: #e4e4e4;
  overflow-x: hidden;
  min-height: 100vh; }

.passport-main--full-screen {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }

.passport-site-wide {
  max-width: 1525px;
  margin: 0 auto;
  padding: 0 25px; }

.align--center {
  text-align: center; }

@-webkit-keyframes fade-in {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fade-in {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #e4e4e4;
  overflow-x: hidden;
  min-height: 100vh; }

*,
:after,
:before {
  box-sizing: border-box; }

*:focus {
  border: none;
  outline: none; }

*:after,
*:before {
  display: block; }

.hidden {
  display: none;
  visibility: hidden; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace; }

.center {
  text-align: center; }

ul {
  margin: 0;
  padding: 0;
  list-style: none; }

img {
  max-width: 100%;
  display: block; }

a {
  color: #db011c; }

.error {
  color: #db011c;
  margin: 0; }

button {
  outline: none;
  border: none;
  background: none;
  padding: 0;
  cursor: pointer; }

main {
  width: 100%;
  margin-left: auto;
  position: relative;
  padding: 30px 0px;
  height: 100vh;
  overflow-y: auto; }

@media (min-width: 768px) {
  main {
    width: calc(100% - 74px);
    padding: 30px; } }

.main--full-screen {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }

.site-wide {
  max-width: 1525px;
  margin: 0 auto;
  padding: 0 25px; }

.app__bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 90%;
  height: 100%;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  opacity: 0.15;
  -webkit-filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.2));
          filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.2));
  z-index: -1;
  pointer-events: none; }

.ftg-heading {
  text-align: center; }

h1 {
  font-family: 'Helvetica Neue LT W01_93 BlkEx', Helvetica, sans-serif;
  text-transform: uppercase;
  font-size: 28px;
  font-style: normal;
  margin: 0px 0px 40px 0px; }

.ru-ru h1,
.pl-pl h1,
.hu-hu h1,
.cs-cz h1,
.et-ee h1,
.lv-lv h1,
.lt-lt h1,
[lang^='ru'] h1,
[lang^='pl'] h1,
[lang^='hu'] h1,
[lang^='cs'] h1,
[lang^='et'] h1,
[lang^='lv'] h1,
[lang^='lt'] h1 {
  font-weight: bold;
  font-family: 'Arimo', sans-serif !important; }

.ru-ru h1 strong,
.pl-pl h1 strong,
.hu-hu h1 strong,
.cs-cz h1 strong,
.et-ee h1 strong,
.lv-lv h1 strong,
.lt-lt h1 strong,
[lang^='ru'] h1 strong,
[lang^='pl'] h1 strong,
[lang^='hu'] h1 strong,
[lang^='cs'] h1 strong,
[lang^='et'] h1 strong,
[lang^='lv'] h1 strong,
[lang^='lt'] h1 strong {
  font-weight: bold;
  font-family: 'Arimo', sans-serif !important; }

.ru-ru h1 b,
.pl-pl h1 b,
.hu-hu h1 b,
.cs-cz h1 b,
.et-ee h1 b,
.lv-lv h1 b,
.lt-lt h1 b,
[lang^='ru'] h1 b,
[lang^='pl'] h1 b,
[lang^='hu'] h1 b,
[lang^='cs'] h1 b,
[lang^='et'] h1 b,
[lang^='lv'] h1 b,
[lang^='lt'] h1 b {
  font-weight: bold;
  font-family: 'Arimo', sans-serif !important; }

h2 {
  font-family: 'Helvetica Neue LT W05_83 Hv Ex', Helvetica, sans-serif;
  text-transform: uppercase;
  font-size: 16px;
  margin: 0px 0px 20px 0px;
  display: flex; }

.ru-ru h2,
.pl-pl h2,
.hu-hu h2,
.cs-cz h2,
.et-ee h2,
.lv-lv h2,
.lt-lt h2,
[lang^='ru'] h2,
[lang^='pl'] h2,
[lang^='hu'] h2,
[lang^='cs'] h2,
[lang^='et'] h2,
[lang^='lv'] h2,
[lang^='lt'] h2 {
  font-weight: bold;
  font-family: 'Arimo', sans-serif !important; }

.ru-ru h2 strong,
.pl-pl h2 strong,
.hu-hu h2 strong,
.cs-cz h2 strong,
.et-ee h2 strong,
.lv-lv h2 strong,
.lt-lt h2 strong,
[lang^='ru'] h2 strong,
[lang^='pl'] h2 strong,
[lang^='hu'] h2 strong,
[lang^='cs'] h2 strong,
[lang^='et'] h2 strong,
[lang^='lv'] h2 strong,
[lang^='lt'] h2 strong {
  font-weight: bold;
  font-family: 'Arimo', sans-serif !important; }

.ru-ru h2 b,
.pl-pl h2 b,
.hu-hu h2 b,
.cs-cz h2 b,
.et-ee h2 b,
.lv-lv h2 b,
.lt-lt h2 b,
[lang^='ru'] h2 b,
[lang^='pl'] h2 b,
[lang^='hu'] h2 b,
[lang^='cs'] h2 b,
[lang^='et'] h2 b,
[lang^='lv'] h2 b,
[lang^='lt'] h2 b {
  font-weight: bold;
  font-family: 'Arimo', sans-serif !important; }

h2 > .align--right {
  margin-left: auto; }

h3,
h4,
h5 {
  font-family: 'Helvetica Neue LT W05_83 Hv Ex', Helvetica, sans-serif;
  font-size: 15px;
  margin: 0px 0px 20px 0px;
  text-transform: uppercase; }

.ru-ru h3,
.pl-pl h3,
.hu-hu h3,
.cs-cz h3,
.et-ee h3,
.lv-lv h3,
.lt-lt h3,
[lang^='ru'] h3,
[lang^='pl'] h3,
[lang^='hu'] h3,
[lang^='cs'] h3,
[lang^='et'] h3,
[lang^='lv'] h3,
[lang^='lt'] h3, .ru-ru
h4,
.pl-pl
h4,
.hu-hu
h4,
.cs-cz
h4,
.et-ee
h4,
.lv-lv
h4,
.lt-lt
h4,
[lang^='ru']
h4,
[lang^='pl']
h4,
[lang^='hu']
h4,
[lang^='cs']
h4,
[lang^='et']
h4,
[lang^='lv']
h4,
[lang^='lt']
h4, .ru-ru
h5,
.pl-pl
h5,
.hu-hu
h5,
.cs-cz
h5,
.et-ee
h5,
.lv-lv
h5,
.lt-lt
h5,
[lang^='ru']
h5,
[lang^='pl']
h5,
[lang^='hu']
h5,
[lang^='cs']
h5,
[lang^='et']
h5,
[lang^='lv']
h5,
[lang^='lt']
h5 {
  font-weight: bold;
  font-family: 'Arimo', sans-serif !important; }

.ru-ru h3 strong,
.pl-pl h3 strong,
.hu-hu h3 strong,
.cs-cz h3 strong,
.et-ee h3 strong,
.lv-lv h3 strong,
.lt-lt h3 strong,
[lang^='ru'] h3 strong,
[lang^='pl'] h3 strong,
[lang^='hu'] h3 strong,
[lang^='cs'] h3 strong,
[lang^='et'] h3 strong,
[lang^='lv'] h3 strong,
[lang^='lt'] h3 strong, .ru-ru
h4 strong,
.pl-pl
h4 strong,
.hu-hu
h4 strong,
.cs-cz
h4 strong,
.et-ee
h4 strong,
.lv-lv
h4 strong,
.lt-lt
h4 strong,
[lang^='ru']
h4 strong,
[lang^='pl']
h4 strong,
[lang^='hu']
h4 strong,
[lang^='cs']
h4 strong,
[lang^='et']
h4 strong,
[lang^='lv']
h4 strong,
[lang^='lt']
h4 strong, .ru-ru
h5 strong,
.pl-pl
h5 strong,
.hu-hu
h5 strong,
.cs-cz
h5 strong,
.et-ee
h5 strong,
.lv-lv
h5 strong,
.lt-lt
h5 strong,
[lang^='ru']
h5 strong,
[lang^='pl']
h5 strong,
[lang^='hu']
h5 strong,
[lang^='cs']
h5 strong,
[lang^='et']
h5 strong,
[lang^='lv']
h5 strong,
[lang^='lt']
h5 strong {
  font-weight: bold;
  font-family: 'Arimo', sans-serif !important; }

.ru-ru h3 b,
.pl-pl h3 b,
.hu-hu h3 b,
.cs-cz h3 b,
.et-ee h3 b,
.lv-lv h3 b,
.lt-lt h3 b,
[lang^='ru'] h3 b,
[lang^='pl'] h3 b,
[lang^='hu'] h3 b,
[lang^='cs'] h3 b,
[lang^='et'] h3 b,
[lang^='lv'] h3 b,
[lang^='lt'] h3 b, .ru-ru
h4 b,
.pl-pl
h4 b,
.hu-hu
h4 b,
.cs-cz
h4 b,
.et-ee
h4 b,
.lv-lv
h4 b,
.lt-lt
h4 b,
[lang^='ru']
h4 b,
[lang^='pl']
h4 b,
[lang^='hu']
h4 b,
[lang^='cs']
h4 b,
[lang^='et']
h4 b,
[lang^='lv']
h4 b,
[lang^='lt']
h4 b, .ru-ru
h5 b,
.pl-pl
h5 b,
.hu-hu
h5 b,
.cs-cz
h5 b,
.et-ee
h5 b,
.lv-lv
h5 b,
.lt-lt
h5 b,
[lang^='ru']
h5 b,
[lang^='pl']
h5 b,
[lang^='hu']
h5 b,
[lang^='cs']
h5 b,
[lang^='et']
h5 b,
[lang^='lv']
h5 b,
[lang^='lt']
h5 b {
  font-weight: bold;
  font-family: 'Arimo', sans-serif !important; }

p {
  margin: 0px 0px 20px 0px; }

p,
body {
  font-family: 'Helvetica Neue LT W01_55 Roman', Helvetica, sans-serif;
  font-size: 14px; }

.ru-ru p,
.pl-pl p,
.hu-hu p,
.cs-cz p,
.et-ee p,
.lv-lv p,
.lt-lt p,
[lang^='ru'] p,
[lang^='pl'] p,
[lang^='hu'] p,
[lang^='cs'] p,
[lang^='et'] p,
[lang^='lv'] p,
[lang^='lt'] p, .ru-ru
body,
.pl-pl
body,
.hu-hu
body,
.cs-cz
body,
.et-ee
body,
.lv-lv
body,
.lt-lt
body,
[lang^='ru']
body,
[lang^='pl']
body,
[lang^='hu']
body,
[lang^='cs']
body,
[lang^='et']
body,
[lang^='lv']
body,
[lang^='lt']
body {
  font-weight: bold;
  font-family: 'Arimo', sans-serif !important; }

.ru-ru p strong,
.pl-pl p strong,
.hu-hu p strong,
.cs-cz p strong,
.et-ee p strong,
.lv-lv p strong,
.lt-lt p strong,
[lang^='ru'] p strong,
[lang^='pl'] p strong,
[lang^='hu'] p strong,
[lang^='cs'] p strong,
[lang^='et'] p strong,
[lang^='lv'] p strong,
[lang^='lt'] p strong, .ru-ru
body strong,
.pl-pl
body strong,
.hu-hu
body strong,
.cs-cz
body strong,
.et-ee
body strong,
.lv-lv
body strong,
.lt-lt
body strong,
[lang^='ru']
body strong,
[lang^='pl']
body strong,
[lang^='hu']
body strong,
[lang^='cs']
body strong,
[lang^='et']
body strong,
[lang^='lv']
body strong,
[lang^='lt']
body strong {
  font-weight: bold;
  font-family: 'Arimo', sans-serif !important; }

.ru-ru p b,
.pl-pl p b,
.hu-hu p b,
.cs-cz p b,
.et-ee p b,
.lv-lv p b,
.lt-lt p b,
[lang^='ru'] p b,
[lang^='pl'] p b,
[lang^='hu'] p b,
[lang^='cs'] p b,
[lang^='et'] p b,
[lang^='lv'] p b,
[lang^='lt'] p b, .ru-ru
body b,
.pl-pl
body b,
.hu-hu
body b,
.cs-cz
body b,
.et-ee
body b,
.lv-lv
body b,
.lt-lt
body b,
[lang^='ru']
body b,
[lang^='pl']
body b,
[lang^='hu']
body b,
[lang^='cs']
body b,
[lang^='et']
body b,
[lang^='lv']
body b,
[lang^='lt']
body b {
  font-weight: bold;
  font-family: 'Arimo', sans-serif !important; }

input,
textarea,
label {
  font-family: 'Helvetica Neue LT W01_55 Roman', Helvetica, sans-serif; }

.ru-ru input,
.pl-pl input,
.hu-hu input,
.cs-cz input,
.et-ee input,
.lv-lv input,
.lt-lt input,
[lang^='ru'] input,
[lang^='pl'] input,
[lang^='hu'] input,
[lang^='cs'] input,
[lang^='et'] input,
[lang^='lv'] input,
[lang^='lt'] input, .ru-ru
textarea,
.pl-pl
textarea,
.hu-hu
textarea,
.cs-cz
textarea,
.et-ee
textarea,
.lv-lv
textarea,
.lt-lt
textarea,
[lang^='ru']
textarea,
[lang^='pl']
textarea,
[lang^='hu']
textarea,
[lang^='cs']
textarea,
[lang^='et']
textarea,
[lang^='lv']
textarea,
[lang^='lt']
textarea, .ru-ru
label,
.pl-pl
label,
.hu-hu
label,
.cs-cz
label,
.et-ee
label,
.lv-lv
label,
.lt-lt
label,
[lang^='ru']
label,
[lang^='pl']
label,
[lang^='hu']
label,
[lang^='cs']
label,
[lang^='et']
label,
[lang^='lv']
label,
[lang^='lt']
label {
  font-weight: bold;
  font-family: 'Arimo', sans-serif !important; }

.ru-ru input strong,
.pl-pl input strong,
.hu-hu input strong,
.cs-cz input strong,
.et-ee input strong,
.lv-lv input strong,
.lt-lt input strong,
[lang^='ru'] input strong,
[lang^='pl'] input strong,
[lang^='hu'] input strong,
[lang^='cs'] input strong,
[lang^='et'] input strong,
[lang^='lv'] input strong,
[lang^='lt'] input strong, .ru-ru
textarea strong,
.pl-pl
textarea strong,
.hu-hu
textarea strong,
.cs-cz
textarea strong,
.et-ee
textarea strong,
.lv-lv
textarea strong,
.lt-lt
textarea strong,
[lang^='ru']
textarea strong,
[lang^='pl']
textarea strong,
[lang^='hu']
textarea strong,
[lang^='cs']
textarea strong,
[lang^='et']
textarea strong,
[lang^='lv']
textarea strong,
[lang^='lt']
textarea strong, .ru-ru
label strong,
.pl-pl
label strong,
.hu-hu
label strong,
.cs-cz
label strong,
.et-ee
label strong,
.lv-lv
label strong,
.lt-lt
label strong,
[lang^='ru']
label strong,
[lang^='pl']
label strong,
[lang^='hu']
label strong,
[lang^='cs']
label strong,
[lang^='et']
label strong,
[lang^='lv']
label strong,
[lang^='lt']
label strong {
  font-weight: bold;
  font-family: 'Arimo', sans-serif !important; }

.ru-ru input b,
.pl-pl input b,
.hu-hu input b,
.cs-cz input b,
.et-ee input b,
.lv-lv input b,
.lt-lt input b,
[lang^='ru'] input b,
[lang^='pl'] input b,
[lang^='hu'] input b,
[lang^='cs'] input b,
[lang^='et'] input b,
[lang^='lv'] input b,
[lang^='lt'] input b, .ru-ru
textarea b,
.pl-pl
textarea b,
.hu-hu
textarea b,
.cs-cz
textarea b,
.et-ee
textarea b,
.lv-lv
textarea b,
.lt-lt
textarea b,
[lang^='ru']
textarea b,
[lang^='pl']
textarea b,
[lang^='hu']
textarea b,
[lang^='cs']
textarea b,
[lang^='et']
textarea b,
[lang^='lv']
textarea b,
[lang^='lt']
textarea b, .ru-ru
label b,
.pl-pl
label b,
.hu-hu
label b,
.cs-cz
label b,
.et-ee
label b,
.lv-lv
label b,
.lt-lt
label b,
[lang^='ru']
label b,
[lang^='pl']
label b,
[lang^='hu']
label b,
[lang^='cs']
label b,
[lang^='et']
label b,
[lang^='lv']
label b,
[lang^='lt']
label b {
  font-weight: bold;
  font-family: 'Arimo', sans-serif !important; }

.align--left {
  text-align: left;
  margin-right: auto; }

.align--right {
  text-align: right;
  margin-left: auto; }

.align--center {
  text-align: center;
  margin-left: auto;
  margin-right: auto; }

@font-face {
  font-family: 'Helvetica Neue LT W01_45 Light';
  font-display: swap;
  font-stretch: normal;
  font-style: normal;
  font-weight: 300;
  src: url(/static/media/752e6c6e-dc02-4393-b814-68a65b594fac.e77e109c.woff2) format("woff2"), url(/static/media/f1892c57-7a6d-457f-9029-d3938c1e80e1.f3342a41.woff) format("woff"); }

@font-face {
  font-family: 'Helvetica Neue LT W01_46 Lt It';
  font-display: swap;
  font-stretch: normal;
  font-style: italic;
  font-weight: 300;
  src: url(/static/media/710b2e3b-d27e-4b19-9ee9-9598d9c5368a.b5a823c6.woff2) format("woff2"), url(/static/media/cabf5256-713c-4838-9aaa-a0a2385d3dec.ed4b62c7.woff) format("woff"); }

@font-face {
  font-family: 'Helvetica Neue LT W05_53 Ext';
  font-display: swap;
  font-stretch: semi-expanded;
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/0504481e-774c-4e57-a055-c94bb5303380.7d9af66c.woff2) format("woff2"), url(/static/media/bf166391-2bf9-47fd-8382-193fffb422c4.b53afa6b.woff) format("woff"); }

@font-face {
  font-family: 'Helvetica Neue LT W05_53 Ex Ob';
  font-display: swap;
  font-stretch: semi-expanded;
  font-style: oblique;
  font-weight: 400;
  src: url(/static/media/d829f114-8f21-4a35-a303-5fc44e646b98.23a50cb7.woff2) format("woff2"), url(/static/media/12be0a3b-503f-4ef3-a94b-0fcce636cd9c.a7f0cad7.woff) format("woff"); }

@font-face {
  font-family: 'Helvetica Neue LT W01_55 Roman';
  font-display: swap;
  font-stretch: normal;
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/02963ef0-dbb8-4ec9-a06d-939c6f252739.671e2036.woff2) format("woff2"), url(/static/media/411f40ef-ca35-4f60-93ce-b394d5485b62.092ebfa1.woff) format("woff"); }

@font-face {
  font-family: 'Helvetica Neue LT W05_63 Md Ex';
  font-display: swap;
  font-style: normal;
  src: url(/static/media/5069b541-02d6-4f13-9ec2-07e07e9d683f.e9a14e95.woff2) format("woff2"), url(/static/media/cef4a236-7e60-42cd-997c-99487ff6bb0a.beccc54b.woff) format("woff"); }

@font-face {
  font-family: 'Helvetica Neue LT W01_73 Bd Ex';
  font-display: swap;
  font-stretch: semi-expanded;
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/b73a7721-ce68-4068-be2f-e354675b54c0.51061515.woff2) format("woff2"), url(/static/media/1be79393-9ab6-4f5e-8ff8-786d00be4238.5d8f1c6a.woff) format("woff"); }

@font-face {
  font-display: swap;
  font-style: normal;
  font-family: 'Helvetica Neue LT W05_83 Hv Ex';
  src: url(/static/media/37cade8a-4499-42b4-b79f-99143dd3a764.b7a370f7.woff2) format("woff2"), url(/static/media/6823dde3-d344-4bb4-b5a4-add12527140d.97b5502f.woff) format("woff"); }

@font-face {
  font-display: swap;
  font-style: oblique;
  font-family: 'Helvetica Neue LT W01_83HvExObl';
  src: url(/static/media/e420593e-9067-41d8-a5be-2ee34c8b2296.c503cff1.woff2) format("woff2"), url(/static/media/2bd00047-167c-4b95-84ea-08fb1ff916a9.d727be70.woff) format("woff"); }

@font-face {
  font-family: 'Helvetica Neue LT W01_93 BlkEx';
  font-display: swap;
  font-stretch: semi-expanded;
  font-style: normal;
  font-weight: 900;
  src: url(/static/media/7de87fb1-de1f-45c3-adef-b60ed40c7beb.d246798e.woff2) format("woff2"), url(/static/media/86c987f4-96f9-4452-bdac-b02d0d3dd276.c7cddc1a.woff) format("woff"); }

@font-face {
  font-family: 'Helvetica Neue LT W0193BlkExObl';
  font-display: swap;
  font-stretch: semi-expanded;
  font-style: oblique;
  font-weight: 900;
  src: url(/static/media/16d5d0fd-f35b-444e-bf60-08c684343755.d98bd906.woff2) format("woff2"), url(/static/media/e679b185-6745-4fba-a93e-7b9ed4a5decb.a05432bc.woff) format("woff"); }

/** 400-Regular */
/* latin */
@font-face {
  font-family: 'Arimo';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Arimo"), url(/static/media/arimo-v12-400-subset.1652aaed.woff2) format("woff2"), url(/static/media/arimo-v12-400-subset.c1193467.woff) format("woff");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000; }

/* latin-ext */
@font-face {
  font-family: 'Arimo';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Arimo"), url(/static/media/arimo-v12-400-subset.9bdc00de.woff2) format("woff2"), url(/static/media/arimo-v12-400-subset.b2e8603e.woff) format("woff");
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF; }

/* cyrillic */
@font-face {
  font-family: 'Arimo';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Arimo"), url(/static/media/arimo-v12-400-subset.52c9b877.woff2) format("woff2"), url(/static/media/arimo-v12-400-subset.4eb36343.woff) format("woff");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116; }

/* cyrillic-ext */
@font-face {
  font-family: 'Arimo';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Arimo"), url(/static/media/arimo-v12-400-subset.8585eec7.woff2) format("woff2"), url(/static/media/arimo-v12-400-subset.27415464.woff) format("woff");
  unicode-range: U+0460-052F, U+20B4, U+2DE0-2DFF, U+A640-A69F; }

/* vietnamese */
@font-face {
  font-family: 'Arimo';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Arimo"), url(/static/media/arimo-v12-400-subset.1fa5a4ad.woff2) format("woff2"), url(/static/media/arimo-v12-400-subset.0a824901.woff) format("woff");
  unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB; }

/** 400-Italic */
/* latin */
@font-face {
  font-family: 'Arimo';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: local("Arimo"), url(/static/media/arimo-v12-400italic-subset.3ecea650.woff2) format("woff2"), url(/static/media/arimo-v12-400italic-subset.93d08617.woff) format("woff");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000; }

/* latin-ext */
@font-face {
  font-family: 'Arimo';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: local("Arimo"), url(/static/media/arimo-v12-400italic-subset.722bfc1a.woff2) format("woff2"), url(/static/media/arimo-v12-400italic-subset.dc4f352c.woff) format("woff");
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF; }

/* cyrillic */
@font-face {
  font-family: 'Arimo';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: local("Arimo"), url(/static/media/arimo-v12-400italic-subset.8f43051d.woff2) format("woff2"), url(/static/media/arimo-v12-400italic-subset.83551c60.woff) format("woff");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116; }

/* cyrillic-ext */
@font-face {
  font-family: 'Arimo';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: local("Arimo"), url(/static/media/arimo-v12-400italic-subset.91e3ac00.woff2) format("woff2"), url(/static/media/arimo-v12-400italic-subset.0db84f1f.woff) format("woff");
  unicode-range: U+0460-052F, U+20B4, U+2DE0-2DFF, U+A640-A69F; }

/* vietnamese */
@font-face {
  font-family: 'Arimo';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: local("Arimo"), url(/static/media/arimo-v12-400italic-subset.208c6d27.woff2) format("woff2"), url(/static/media/arimo-v12-400italic-subset.84427234.woff) format("woff");
  unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB; }

/** 700-Regular */
/* latin */
@font-face {
  font-family: 'Arimo';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local("Arimo"), url(/static/media/arimo-v12-700-subset.0f09d269.woff2) format("woff2"), url(/static/media/arimo-v12-700-subset.cdc1b6c6.woff) format("woff");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000; }

/* latin-ext */
@font-face {
  font-family: 'Arimo';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local("Arimo"), url(/static/media/arimo-v12-700-subset.7e1f1041.woff2) format("woff2"), url(/static/media/arimo-v12-700-subset.1249c9b1.woff) format("woff");
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF; }

/* cyrillic */
@font-face {
  font-family: 'Arimo';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local("Arimo"), url(/static/media/arimo-v12-700-subset.09eba2f4.woff2) format("woff2"), url(/static/media/arimo-v12-700-subset.21a57d67.woff) format("woff");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116; }

/* cyrillic-ext */
@font-face {
  font-family: 'Arimo';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local("Arimo"), url(/static/media/arimo-v12-700-subset.5e44669a.woff2) format("woff2"), url(/static/media/arimo-v12-700-subset.75cf8dd6.woff) format("woff");
  unicode-range: U+0460-052F, U+20B4, U+2DE0-2DFF, U+A640-A69F; }

/* vietnamese */
@font-face {
  font-family: 'Arimo';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local("Arimo"), url(/static/media/arimo-v12-700-subset.f8da0666.woff2) format("woff2"), url(/static/media/arimo-v12-700-subset.1c80b3f7.woff) format("woff");
  unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB; }

/** 700-Italic */
/* latin */
@font-face {
  font-family: 'Arimo';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: local("Arimo"), url(/static/media/arimo-v12-700italic-subset.03edf0e2.woff2) format("woff2"), url(/static/media/arimo-v12-700italic-subset.5f0e6844.woff) format("woff");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000; }

/* latin-ext */
@font-face {
  font-family: 'Arimo';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: local("Arimo"), url(/static/media/arimo-v12-700italic-subset.e7498c08.woff2) format("woff2"), url(/static/media/arimo-v12-700italic-subset.b2ad748f.woff) format("woff");
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF; }

/* cyrillic */
@font-face {
  font-family: 'Arimo';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: local("Arimo"), url(/static/media/arimo-v12-700italic-subset.d247a666.woff2) format("woff2"), url(/static/media/arimo-v12-700italic-subset.13cabdab.woff) format("woff");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116; }

/* cyrillic-ext */
@font-face {
  font-family: 'Arimo';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: local("Arimo"), url(/static/media/arimo-v12-700italic-subset.a3d20135.woff2) format("woff2"), url(/static/media/arimo-v12-700italic-subset.314f6ec5.woff) format("woff");
  unicode-range: U+0460-052F, U+20B4, U+2DE0-2DFF, U+A640-A69F; }

/* vietnamese */
@font-face {
  font-family: 'Arimo';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: local("Arimo"), url(/static/media/arimo-v12-700italic-subset.862205d8.woff2) format("woff2"), url(/static/media/arimo-v12-700italic-subset.68177334.woff) format("woff");
  unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB; }

/** 400-Regular */
/* latin */
@font-face {
  font-family: 'Andika';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Andika"), url(/static/media/andika-v10-subset.5951aca9.woff2) format("woff2"), url(/static/media/andika-v10-subset.060a29fc.woff) format("woff");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000; }

/* latin-ext */
@font-face {
  font-family: 'Andika';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Andika"), url(/static/media/andika-v10-subset.c1d5f65e.woff2) format("woff2"), url(/static/media/andika-v10-subset.51ed8620.woff) format("woff");
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF; }

/* cyrillic */
@font-face {
  font-family: 'Andika';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Andika"), url(/static/media/andika-v10-subset.afb91873.woff2) format("woff2"), url(/static/media/andika-v10-subset.7fb500a8.woff) format("woff");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116; }

/* cyrillic-ext */
@font-face {
  font-family: 'Andika';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Andika"), url(/static/media/andika-v10-subset.896a228c.woff2) format("woff2"), url(/static/media/andika-v10-subset.3bbdced9.woff) format("woff");
  unicode-range: U+0460-052F, U+20B4, U+2DE0-2DFF, U+A640-A69F; }

/* vietnamese */
@font-face {
  font-family: 'Andika';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Andika"), url(/static/media/andika-v10-subset.56808bbe.woff2) format("woff2"), url(/static/media/andika-v10-subset.9a40081f.woff) format("woff");
  unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB; }

.btn {
  font-family: 'Helvetica Neue LT W05_83 Hv Ex', Helvetica, sans-serif;
  text-transform: uppercase;
  font-size: 16px;
  padding: 14px 20px;
  color: #fff;
  background-color: #db011c;
  display: block;
  width: 100%;
  text-decoration: none; }

.ru-ru .btn,
.pl-pl .btn,
.hu-hu .btn,
.cs-cz .btn,
.et-ee .btn,
.lv-lv .btn,
.lt-lt .btn,
[lang^='ru'] .btn,
[lang^='pl'] .btn,
[lang^='hu'] .btn,
[lang^='cs'] .btn,
[lang^='et'] .btn,
[lang^='lv'] .btn,
[lang^='lt'] .btn {
  font-weight: bold;
  font-family: 'Arimo', sans-serif !important; }

.ru-ru .btn strong,
.pl-pl .btn strong,
.hu-hu .btn strong,
.cs-cz .btn strong,
.et-ee .btn strong,
.lv-lv .btn strong,
.lt-lt .btn strong,
[lang^='ru'] .btn strong,
[lang^='pl'] .btn strong,
[lang^='hu'] .btn strong,
[lang^='cs'] .btn strong,
[lang^='et'] .btn strong,
[lang^='lv'] .btn strong,
[lang^='lt'] .btn strong {
  font-weight: bold;
  font-family: 'Arimo', sans-serif !important; }

.ru-ru .btn b,
.pl-pl .btn b,
.hu-hu .btn b,
.cs-cz .btn b,
.et-ee .btn b,
.lv-lv .btn b,
.lt-lt .btn b,
[lang^='ru'] .btn b,
[lang^='pl'] .btn b,
[lang^='hu'] .btn b,
[lang^='cs'] .btn b,
[lang^='et'] .btn b,
[lang^='lv'] .btn b,
[lang^='lt'] .btn b {
  font-weight: bold;
  font-family: 'Arimo', sans-serif !important; }

.tabs__create .btn {
  font-size: 14px;
  padding: 8px 15px; }

.btn--small {
  font-family: 'Helvetica Neue LT W05_63 Md Ex', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  margin: 0 10px; }

.ru-ru .btn--small,
.pl-pl .btn--small,
.hu-hu .btn--small,
.cs-cz .btn--small,
.et-ee .btn--small,
.lv-lv .btn--small,
.lt-lt .btn--small,
[lang^='ru'] .btn--small,
[lang^='pl'] .btn--small,
[lang^='hu'] .btn--small,
[lang^='cs'] .btn--small,
[lang^='et'] .btn--small,
[lang^='lv'] .btn--small,
[lang^='lt'] .btn--small {
  font-weight: bold;
  font-family: 'Arimo', sans-serif !important; }

.ru-ru .btn--small strong,
.pl-pl .btn--small strong,
.hu-hu .btn--small strong,
.cs-cz .btn--small strong,
.et-ee .btn--small strong,
.lv-lv .btn--small strong,
.lt-lt .btn--small strong,
[lang^='ru'] .btn--small strong,
[lang^='pl'] .btn--small strong,
[lang^='hu'] .btn--small strong,
[lang^='cs'] .btn--small strong,
[lang^='et'] .btn--small strong,
[lang^='lv'] .btn--small strong,
[lang^='lt'] .btn--small strong {
  font-weight: bold;
  font-family: 'Arimo', sans-serif !important; }

.ru-ru .btn--small b,
.pl-pl .btn--small b,
.hu-hu .btn--small b,
.cs-cz .btn--small b,
.et-ee .btn--small b,
.lv-lv .btn--small b,
.lt-lt .btn--small b,
[lang^='ru'] .btn--small b,
[lang^='pl'] .btn--small b,
[lang^='hu'] .btn--small b,
[lang^='cs'] .btn--small b,
[lang^='et'] .btn--small b,
[lang^='lv'] .btn--small b,
[lang^='lt'] .btn--small b {
  font-weight: bold;
  font-family: 'Arimo', sans-serif !important; }

.btn--link {
  font-family: 'Helvetica Neue LT W01_55 Roman', Helvetica, sans-serif;
  font-style: normal;
  font-weight: normal;
  color: #db011c;
  margin-left: 5px;
  padding: 0;
  font-size: 14px;
  background-color: transparent;
  text-decoration: underline;
  text-transform: none;
  display: inline;
  width: auto;
  margin: 0; }

.ru-ru .btn--link,
.pl-pl .btn--link,
.hu-hu .btn--link,
.cs-cz .btn--link,
.et-ee .btn--link,
.lv-lv .btn--link,
.lt-lt .btn--link,
[lang^='ru'] .btn--link,
[lang^='pl'] .btn--link,
[lang^='hu'] .btn--link,
[lang^='cs'] .btn--link,
[lang^='et'] .btn--link,
[lang^='lv'] .btn--link,
[lang^='lt'] .btn--link {
  font-weight: bold;
  font-family: 'Arimo', sans-serif !important; }

.ru-ru .btn--link strong,
.pl-pl .btn--link strong,
.hu-hu .btn--link strong,
.cs-cz .btn--link strong,
.et-ee .btn--link strong,
.lv-lv .btn--link strong,
.lt-lt .btn--link strong,
[lang^='ru'] .btn--link strong,
[lang^='pl'] .btn--link strong,
[lang^='hu'] .btn--link strong,
[lang^='cs'] .btn--link strong,
[lang^='et'] .btn--link strong,
[lang^='lv'] .btn--link strong,
[lang^='lt'] .btn--link strong {
  font-weight: bold;
  font-family: 'Arimo', sans-serif !important; }

.ru-ru .btn--link b,
.pl-pl .btn--link b,
.hu-hu .btn--link b,
.cs-cz .btn--link b,
.et-ee .btn--link b,
.lv-lv .btn--link b,
.lt-lt .btn--link b,
[lang^='ru'] .btn--link b,
[lang^='pl'] .btn--link b,
[lang^='hu'] .btn--link b,
[lang^='cs'] .btn--link b,
[lang^='et'] .btn--link b,
[lang^='lv'] .btn--link b,
[lang^='lt'] .btn--link b {
  font-weight: bold;
  font-family: 'Arimo', sans-serif !important; }

.btn--inline {
  display: inline-block;
  width: auto; }

.btn--small {
  font-size: 12px;
  padding: 12px 16px;
  cursor: default; }

.btn--black {
  background-color: #000; }

.btn--dark-grey {
  background-color: #383739; }

.btn--grey {
  background-color: #b5b5b5; }

.btn--grey:active, .btn--grey.active {
  background-color: #db011c; }

.btn--disabled, .btn[disabled] {
  cursor: not-allowed;
  background-color: #b5b5b5; }

.btn--disabled:active, .btn--disabled.active, .btn[disabled]:active, .btn[disabled].active {
  background-color: #b5b5b5; }

.form {
  margin: 0 auto;
  position: relative;
  width: 80vw;
  max-width: 1420px; }

.form__close {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 0;
  right: 0;
  color: #fff;
  background-color: #db011c;
  z-index: 1;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.form__close svg {
  width: 100%;
  height: 100%; }

.form__navigation-wrapper {
  display: flex;
  flex-direction: row;
  height: 720px;
  max-height: 80vh; }

.form__navigation-wrapper__items {
  display: flex;
  flex-direction: column;
  background: #383739;
  width: 20vw;
  max-width: 255px; }

.form__navigation-wrapper__items button {
  font-family: 'Helvetica Neue LT W05_83 Hv Ex', Helvetica, sans-serif;
  text-transform: uppercase;
  font-size: 14px;
  text-align: left;
  color: #fff;
  padding: 24px 16px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 12px;
  gap: 12px;
  position: relative; }

.ru-ru .form__navigation-wrapper__items button,
.pl-pl .form__navigation-wrapper__items button,
.hu-hu .form__navigation-wrapper__items button,
.cs-cz .form__navigation-wrapper__items button,
.et-ee .form__navigation-wrapper__items button,
.lv-lv .form__navigation-wrapper__items button,
.lt-lt .form__navigation-wrapper__items button,
[lang^='ru'] .form__navigation-wrapper__items button,
[lang^='pl'] .form__navigation-wrapper__items button,
[lang^='hu'] .form__navigation-wrapper__items button,
[lang^='cs'] .form__navigation-wrapper__items button,
[lang^='et'] .form__navigation-wrapper__items button,
[lang^='lv'] .form__navigation-wrapper__items button,
[lang^='lt'] .form__navigation-wrapper__items button {
  font-weight: bold;
  font-family: 'Arimo', sans-serif !important; }

.ru-ru .form__navigation-wrapper__items button strong,
.pl-pl .form__navigation-wrapper__items button strong,
.hu-hu .form__navigation-wrapper__items button strong,
.cs-cz .form__navigation-wrapper__items button strong,
.et-ee .form__navigation-wrapper__items button strong,
.lv-lv .form__navigation-wrapper__items button strong,
.lt-lt .form__navigation-wrapper__items button strong,
[lang^='ru'] .form__navigation-wrapper__items button strong,
[lang^='pl'] .form__navigation-wrapper__items button strong,
[lang^='hu'] .form__navigation-wrapper__items button strong,
[lang^='cs'] .form__navigation-wrapper__items button strong,
[lang^='et'] .form__navigation-wrapper__items button strong,
[lang^='lv'] .form__navigation-wrapper__items button strong,
[lang^='lt'] .form__navigation-wrapper__items button strong {
  font-weight: bold;
  font-family: 'Arimo', sans-serif !important; }

.ru-ru .form__navigation-wrapper__items button b,
.pl-pl .form__navigation-wrapper__items button b,
.hu-hu .form__navigation-wrapper__items button b,
.cs-cz .form__navigation-wrapper__items button b,
.et-ee .form__navigation-wrapper__items button b,
.lv-lv .form__navigation-wrapper__items button b,
.lt-lt .form__navigation-wrapper__items button b,
[lang^='ru'] .form__navigation-wrapper__items button b,
[lang^='pl'] .form__navigation-wrapper__items button b,
[lang^='hu'] .form__navigation-wrapper__items button b,
[lang^='cs'] .form__navigation-wrapper__items button b,
[lang^='et'] .form__navigation-wrapper__items button b,
[lang^='lv'] .form__navigation-wrapper__items button b,
[lang^='lt'] .form__navigation-wrapper__items button b {
  font-weight: bold;
  font-family: 'Arimo', sans-serif !important; }

.form__navigation-wrapper__items button[data-active="true"] {
  background: #db011c; }

.form__navigation-wrapper__items button:after {
  content: '';
  width: 24px;
  height: 24px;
  flex-shrink: 0; }

.form__navigation-wrapper__items button[disabled] {
  cursor: not-allowed;
  color: #b5b5b5; }

.form__navigation-wrapper__items button[disabled]:after {
  background-image: url(/static/media/svg--lock.fcc0d64a.svg); }

.form__navigation-wrapper__items button:not([disabled]):has(+ button:not([disabled])):after {
  background-image: url(/static/media/svg--tick.cf1b2eea.svg); }

.form__navigation-wrapper__content {
  display: flex;
  flex-direction: column;
  width: 100%; }

.form__navigation-wrapper__content .form__inner {
  flex: 1 1;
  overflow: auto; }

.form__button-wrapper {
  background-color: #fff;
  display: flex;
  flex-direction: row;
  padding: 12px 24px;
  grid-gap: 12px;
  gap: 12px; }

.form__button-wrapper .btn {
  flex: 1 1 100%; }

.form__button-wrapper .btn--next, .form__button-wrapper .btn--previous {
  display: flex;
  justify-content: center;
  grid-gap: 8px;
  gap: 8px; }

.form__button-wrapper .btn--next:not([disabled]), .form__button-wrapper .btn--previous:not([disabled]) {
  background-color: #000; }

.form__button-wrapper .btn--next:after {
  content: '';
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  background-image: url(/static/media/svg--right.8fbb19a2.svg); }

.form__button-wrapper .btn--previous:before {
  content: '';
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  background-image: url(/static/media/svg--left.db154c14.svg); }

.form .image-uploader {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 160px;
  border: 2px solid #231f20;
  border-radius: 8px;
  text-align: center;
  cursor: pointer;
  position: relative;
  -webkit-transition: background-color 0.2s, border-color 0.2s;
  transition: background-color 0.2s, border-color 0.2s;
  padding: 8px; }

.form .image-uploader--dragging {
  background-color: #b5b5b5;
  border-color: #db011c; }

.form .image-uploader__placeholder {
  font-family: 'Helvetica Neue LT W05_83 Hv Ex', Helvetica, sans-serif;
  text-transform: uppercase;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 24px;
  gap: 24px; }

.ru-ru .form .image-uploader__placeholder,
.pl-pl .form .image-uploader__placeholder,
.hu-hu .form .image-uploader__placeholder,
.cs-cz .form .image-uploader__placeholder,
.et-ee .form .image-uploader__placeholder,
.lv-lv .form .image-uploader__placeholder,
.lt-lt .form .image-uploader__placeholder,
[lang^='ru'] .form .image-uploader__placeholder,
[lang^='pl'] .form .image-uploader__placeholder,
[lang^='hu'] .form .image-uploader__placeholder,
[lang^='cs'] .form .image-uploader__placeholder,
[lang^='et'] .form .image-uploader__placeholder,
[lang^='lv'] .form .image-uploader__placeholder,
[lang^='lt'] .form .image-uploader__placeholder {
  font-weight: bold;
  font-family: 'Arimo', sans-serif !important; }

.ru-ru .form .image-uploader__placeholder strong,
.pl-pl .form .image-uploader__placeholder strong,
.hu-hu .form .image-uploader__placeholder strong,
.cs-cz .form .image-uploader__placeholder strong,
.et-ee .form .image-uploader__placeholder strong,
.lv-lv .form .image-uploader__placeholder strong,
.lt-lt .form .image-uploader__placeholder strong,
[lang^='ru'] .form .image-uploader__placeholder strong,
[lang^='pl'] .form .image-uploader__placeholder strong,
[lang^='hu'] .form .image-uploader__placeholder strong,
[lang^='cs'] .form .image-uploader__placeholder strong,
[lang^='et'] .form .image-uploader__placeholder strong,
[lang^='lv'] .form .image-uploader__placeholder strong,
[lang^='lt'] .form .image-uploader__placeholder strong {
  font-weight: bold;
  font-family: 'Arimo', sans-serif !important; }

.ru-ru .form .image-uploader__placeholder b,
.pl-pl .form .image-uploader__placeholder b,
.hu-hu .form .image-uploader__placeholder b,
.cs-cz .form .image-uploader__placeholder b,
.et-ee .form .image-uploader__placeholder b,
.lv-lv .form .image-uploader__placeholder b,
.lt-lt .form .image-uploader__placeholder b,
[lang^='ru'] .form .image-uploader__placeholder b,
[lang^='pl'] .form .image-uploader__placeholder b,
[lang^='hu'] .form .image-uploader__placeholder b,
[lang^='cs'] .form .image-uploader__placeholder b,
[lang^='et'] .form .image-uploader__placeholder b,
[lang^='lv'] .form .image-uploader__placeholder b,
[lang^='lt'] .form .image-uploader__placeholder b {
  font-weight: bold;
  font-family: 'Arimo', sans-serif !important; }

.form .image-uploader__placeholder::before {
  content: '';
  width: 28px;
  height: 32px;
  background-image: url(/static/media/svg--upload.a83c52cd.svg);
  flex-shrink: 0; }

.form .image-uploader__img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  border-radius: 5px; }

.form .image-uploader__input {
  display: none; }

.form .image-uploader__remove {
  margin-top: 0.5rem;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 12px;
  padding: 10px 15px; }

.form .image-uploader__remove:hover {
  background-color: #c82333; }

.form__inner {
  padding: 30px;
  background-color: #fff; }

.form__inner--confirmation {
  border-bottom: 4px solid #db011c; }

.form__inner .confirmation {
  text-align: center; }

.form__inner .confirmation__title {
  margin-top: 10px; }

.form__inner .confirmation__text {
  padding: 0 30px; }

@media (min-width: 768px) {
  .form__inner .confirmation__text {
    padding: 0 60px; } }

.form__row {
  width: 100%;
  position: relative;
  display: inline-block;
  margin-top: 20px;
  margin-bottom: 10px; }

.form__split {
  width: 48%;
  display: flex; }

.form__split .form__dropdown-values {
  width: 10vw;
  min-width: 250px; }

.form__split .form__dropdown--search {
  width: 100%; }

.form__split > div:first-of-type {
  width: 35%; }

.form__split > input:first-of-type {
  width: 65%; }

.form__label {
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  margin: 0;
  font-size: 11px;
  line-height: 20px;
  opacity: 1;
  -webkit-transform: translateY(-100%);
          transform: translateY(-100%);
  -webkit-transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  z-index: 1;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.form__control:not([type='checkbox']):not([type='radio']):placeholder-shown + .form__label,
.form__control[data-placeholder-shown="true"] .form__label {
  opacity: 0;
  -webkit-transform: translateY(0);
          transform: translateY(0); }

.form textarea.form__control {
  max-width: 100%;
  min-width: 100%;
  min-height: 120px;
  max-height: 240px; }

.form__control {
  width: 100%;
  position: relative;
  height: 40px;
  padding: 10px 10px 9px;
  border-bottom: 2px solid #231f20;
  background: #fff;
  box-shadow: none;
  font-size: 14px;
  letter-spacing: 1px;
  line-height: 20px;
  -webkit-transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px; }

.form__control input, .form__control select {
  border: 0;
  outline: 0;
  background: #fff;
  border-bottom: 2px solid #231f20;
  height: 40px;
  flex: 1 1; }

.form__control--icon {
  cursor: default; }

.form__control:has(input) select,
.form__control:has(input) .form__control--icon {
  flex: 0 1 16px;
  padding: 0;
  font-weight: bold; }

.form__control:has(input) select option,
.form__control:has(input) .form__control--icon option {
  text-align: center; }

.form__control .form__control:focus {
  background: #fbfbfb;
  box-shadow: none;
  outline: 0; }

.form__control.invalid,
.invalid .form__control {
  border-bottom-color: #db011c; }

.form__control.invalid::-webkit-input-placeholder,
.invalid .form__control::-webkit-input-placeholder {
  color: #db011c; }

.form__control.invalid::-moz-placeholder,
.invalid .form__control::-moz-placeholder {
  color: #db011c; }

.form__control.invalid:-ms-input-placeholder,
.invalid .form__control:-ms-input-placeholder {
  color: #db011c; }

.form__control.invalid::-ms-input-placeholder,
.invalid .form__control::-ms-input-placeholder {
  color: #db011c; }

.form__control.invalid::placeholder,
.invalid .form__control::placeholder {
  color: #db011c; }

input[type='checkbox'] .form__control,
input[type='radio'] .form__control {
  display: none; }

.form__control .form__select-wrapper {
  position: relative; }

.form__control .form__select-wrapper:after {
  content: '';
  right: 10px;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }

select .form__control, .form__control {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer; }

textarea .form__control {
  height: auto;
  padding: 10px 19px 9px;
  border: 1px solid fadeout(#323232, 50%);
  border-radius: 5px;
  min-height: 120px; }

.invalid textarea .form__control {
  border-color: #db011c; }

input[type='text']:-moz-read-only .form__control {
  cursor: not-allowed;
  -moz-user-select: none;
       user-select: none;
  color: fade(#000, 50%); }

input[type='text']:read-only .form__control {
  cursor: not-allowed;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  color: fade(#000, 50%); }

.form__dropdown {
  cursor: pointer;
  z-index: 3;
  flex-grow: 1; }

.form__dropdown-values {
  display: none;
  background-color: #fbfbfb;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  z-index: 2;
  max-height: 280px;
  overflow-y: auto; }

.form__dropdown-selected {
  position: relative;
  z-index: 1; }

.invalid .form__dropdown-selected {
  color: #db011c; }

.form__dropdown-selected:after {
  content: '';
  z-index: 2;
  top: 50%;
  right: 0px;
  width: 12px;
  height: 6px;
  position: absolute;
  background-image: url(/static/media/svg--dropdown-arrow.8b7edad9.svg);
  background-size: contain;
  background-repeat: no-repeat; }

.form__dropdown-value {
  padding: 10px 20px 9px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.form__dropdown-value:hover {
  background-color: #f4f4f4; }

.form__dropdown.active {
  background-color: #fbfbfb;
  z-index: 5; }

.form__dropdown.active .form__dropdown-values {
  display: block; }

.form__checkbox {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.form__checkbox.active:before {
  background-image: url(/static/media/svg--checked.65e39675.svg); }

.form__checkbox:before {
  content: '';
  position: relative;
  width: 20px;
  height: 20px;
  margin-right: 10px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-image: url(/static/media/svg--unchecked--black.edfcef33.svg); }

.form__forgotten-password {
  font-family: 'Helvetica Neue LT W01_55 Roman', Helvetica, sans-serif;
  margin: 5px 0;
  font-size: 13px; }

.ru-ru .form__forgotten-password,
.pl-pl .form__forgotten-password,
.hu-hu .form__forgotten-password,
.cs-cz .form__forgotten-password,
.et-ee .form__forgotten-password,
.lv-lv .form__forgotten-password,
.lt-lt .form__forgotten-password,
[lang^='ru'] .form__forgotten-password,
[lang^='pl'] .form__forgotten-password,
[lang^='hu'] .form__forgotten-password,
[lang^='cs'] .form__forgotten-password,
[lang^='et'] .form__forgotten-password,
[lang^='lv'] .form__forgotten-password,
[lang^='lt'] .form__forgotten-password {
  font-weight: bold;
  font-family: 'Arimo', sans-serif !important; }

.ru-ru .form__forgotten-password strong,
.pl-pl .form__forgotten-password strong,
.hu-hu .form__forgotten-password strong,
.cs-cz .form__forgotten-password strong,
.et-ee .form__forgotten-password strong,
.lv-lv .form__forgotten-password strong,
.lt-lt .form__forgotten-password strong,
[lang^='ru'] .form__forgotten-password strong,
[lang^='pl'] .form__forgotten-password strong,
[lang^='hu'] .form__forgotten-password strong,
[lang^='cs'] .form__forgotten-password strong,
[lang^='et'] .form__forgotten-password strong,
[lang^='lv'] .form__forgotten-password strong,
[lang^='lt'] .form__forgotten-password strong {
  font-weight: bold;
  font-family: 'Arimo', sans-serif !important; }

.ru-ru .form__forgotten-password b,
.pl-pl .form__forgotten-password b,
.hu-hu .form__forgotten-password b,
.cs-cz .form__forgotten-password b,
.et-ee .form__forgotten-password b,
.lv-lv .form__forgotten-password b,
.lt-lt .form__forgotten-password b,
[lang^='ru'] .form__forgotten-password b,
[lang^='pl'] .form__forgotten-password b,
[lang^='hu'] .form__forgotten-password b,
[lang^='cs'] .form__forgotten-password b,
[lang^='et'] .form__forgotten-password b,
[lang^='lv'] .form__forgotten-password b,
[lang^='lt'] .form__forgotten-password b {
  font-weight: bold;
  font-family: 'Arimo', sans-serif !important; }

.form__forgotten-password a {
  font-family: 'Helvetica Neue LT W05_63 Md Ex', sans-serif;
  font-style: normal;
  font-weight: normal;
  color: #db011c;
  margin-left: 5px; }

.ru-ru .form__forgotten-password a,
.pl-pl .form__forgotten-password a,
.hu-hu .form__forgotten-password a,
.cs-cz .form__forgotten-password a,
.et-ee .form__forgotten-password a,
.lv-lv .form__forgotten-password a,
.lt-lt .form__forgotten-password a,
[lang^='ru'] .form__forgotten-password a,
[lang^='pl'] .form__forgotten-password a,
[lang^='hu'] .form__forgotten-password a,
[lang^='cs'] .form__forgotten-password a,
[lang^='et'] .form__forgotten-password a,
[lang^='lv'] .form__forgotten-password a,
[lang^='lt'] .form__forgotten-password a {
  font-weight: bold;
  font-family: 'Arimo', sans-serif !important; }

.ru-ru .form__forgotten-password a strong,
.pl-pl .form__forgotten-password a strong,
.hu-hu .form__forgotten-password a strong,
.cs-cz .form__forgotten-password a strong,
.et-ee .form__forgotten-password a strong,
.lv-lv .form__forgotten-password a strong,
.lt-lt .form__forgotten-password a strong,
[lang^='ru'] .form__forgotten-password a strong,
[lang^='pl'] .form__forgotten-password a strong,
[lang^='hu'] .form__forgotten-password a strong,
[lang^='cs'] .form__forgotten-password a strong,
[lang^='et'] .form__forgotten-password a strong,
[lang^='lv'] .form__forgotten-password a strong,
[lang^='lt'] .form__forgotten-password a strong {
  font-weight: bold;
  font-family: 'Arimo', sans-serif !important; }

.ru-ru .form__forgotten-password a b,
.pl-pl .form__forgotten-password a b,
.hu-hu .form__forgotten-password a b,
.cs-cz .form__forgotten-password a b,
.et-ee .form__forgotten-password a b,
.lv-lv .form__forgotten-password a b,
.lt-lt .form__forgotten-password a b,
[lang^='ru'] .form__forgotten-password a b,
[lang^='pl'] .form__forgotten-password a b,
[lang^='hu'] .form__forgotten-password a b,
[lang^='cs'] .form__forgotten-password a b,
[lang^='et'] .form__forgotten-password a b,
[lang^='lv'] .form__forgotten-password a b,
[lang^='lt'] .form__forgotten-password a b {
  font-weight: bold;
  font-family: 'Arimo', sans-serif !important; }

.form__fields {
  display: flex;
  flex-direction: column;
  width: 100%; }

.form__fields .product-info {
  display: flex;
  flex-direction: row;
  grid-gap: 32px;
  gap: 32px; }

.form__fields .product-info__content {
  flex: 1 1;
  display: grid;
  grid-template-columns: 120px 1fr;
  grid-gap: 16px;
  gap: 16px; }

.form__fields .product-info__content p {
  margin-bottom: 0; }

.form__fields .product-info__content__features-list {
  display: flex;
  flex-direction: column;
  grid-gap: 8px;
  gap: 8px;
  padding-left: 16px; }

.form__fields .product-info__content__features-list li {
  position: relative; }

.form__fields .product-info__content__features-list li:before {
  position: absolute;
  content: '•';
  left: -16px; }

.form__fields .product-info img {
  object-fit: contain;
  object-position: top; }

.form__fields--split {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between; }

.form__fields--split .form__row {
  width: 48%;
  margin-right: 5px; }

.form__fields--split .form__row:nth-child(2n) {
  margin-right: 0px; }

.form__fields--split .form__row--full {
  width: 100%; }

.form__fields--split--half {
  width: 48%;
  display: flex;
  flex-direction: column; }

.form__fields--split--half .form__row {
  width: 100%; }

.form__fields--format {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px;
  gap: 16px; }

.form__fields--format embed {
  width: 100%;
  border: 2px solid #000;
  border-radius: 4px; }

.form__fields--format embed[data-template$="a4"] {
  aspect-ratio: 794 / 1123; }

.form__fields-drag-list {
  display: grid;
  grid-template-columns: auto 1fr; }

.form__fields-drag-list-number {
  height: 40px;
  width: 60px;
  margin-top: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: 700;
  background: #db011c;
  color: #fff;
  font-size: 28px; }

.form__fields-drag-list-number[data-inactive='true'] {
  background: #f1f1f1;
  color: #000;
  opacity: 0.5; }

.form__fields-drag-list-item {
  height: 40px;
  margin: 4px 0 0 4px;
  background: #f1f1f1;
  color: #000;
  cursor: -webkit-grab;
  cursor: grab;
  font-size: 16px;
  display: flex;
  padding: 8px 0 8px 16px;
  grid-gap: 16px;
  gap: 16px;
  align-items: center; }

.form__fields-drag-list-item[data-dragging='true'] {
  background-color: #db011c; }

.form__fields-drag-list-item[data-inactive='true'] span {
  opacity: 0.5; }

.form__fields-drag-list-item:before {
  content: '';
  width: 24px;
  height: 24px;
  background-image: url(/static/media/svg--drag.1934ffdc.svg);
  flex-shrink: 0; }

.form__fields-drag-list-item-up, .form__fields-drag-list-item-down {
  cursor: pointer;
  padding: 16px 16px 16px 0; }

.form__fields-drag-list-item-up:before, .form__fields-drag-list-item-down:before {
  content: '';
  width: 24px;
  height: 12px;
  background-image: url(/static/media/svg--dropdown-arrow.8b7edad9.svg);
  background-size: contain;
  background-repeat: no-repeat; }

.form__fields-drag-list-item-up {
  margin-left: auto;
  margin-right: -12px;
  padding: 16px 0 16px 16px; }

.form__fields-drag-list-item-up:before {
  -webkit-transform: scaleY(-1);
          transform: scaleY(-1); }

.form__validation-message {
  background-color: #fff;
  margin-bottom: 0;
  text-align: center;
  padding-bottom: 35px;
  color: #db011c;
  font-size: 15px; }

.form-modal {
  display: none; }

.form-modal.is-active {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 1001;
  top: 0;
  left: 0;
  overflow-y: auto; }

.form-modal.is-active .form {
  z-index: 1002;
  max-height: 100vh; }

@media (max-width: 768px) {
  .form-modal.is-active .form {
    width: 90%; } }

.form-modal.is-active .form__overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5); }

.navigation {
  width: 74px;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #383739;
  flex-direction: column;
  z-index: 1000;
  display: none; }

@media (min-width: 768px) {
  .navigation {
    display: flex; } }

.navigation-logo {
  width: 100%;
  height: 74px;
  background-color: #db011c;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: nowrap; }

.navigation-logo svg {
  height: 27px;
  width: 62px;
  margin: 0 auto; }

.navigation__content {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  padding-top: 5px;
  position: relative;
  overflow-y: auto;
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Firefox */ }

.navigation__content::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */ }

.navigation__content--bottom {
  margin-top: auto; }

.navigation__flag {
  width: 50px;
  height: 50px;
  margin: 0 auto;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center; }

.navigation__icon-tooltip {
  position: absolute;
  top: 2px;
  right: -10px;
  background-color: red;
  z-index: 10;
  opacity: 1;
  color: white;
  border-radius: 50%;
  width: 20px;
  text-align: center;
  height: 20px; }

.navigation__icon {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 30px;
  height: 50px;
  margin: 0 auto;
  -webkit-transition: opacity 375ms ease;
  transition: opacity 375ms ease;
  opacity: 0.3;
  cursor: pointer;
  display: block;
  color: #fff;
  position: relative; }

.navigation__icon svg {
  display: block;
  width: 100%;
  height: 100%; }

.navigation__icon:hover, .navigation__icon.active {
  opacity: 1; }

.navigation__text {
  font-family: 'Helvetica Neue LT W05_63 Md Ex', sans-serif;
  font-style: normal;
  font-weight: normal;
  width: 100%;
  height: 74px;
  min-height: 50px;
  margin: 0 auto;
  -webkit-transition: opacity 375ms ease;
  transition: opacity 375ms ease;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 12px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  text-align: center; }

.ru-ru .navigation__text,
.pl-pl .navigation__text,
.hu-hu .navigation__text,
.cs-cz .navigation__text,
.et-ee .navigation__text,
.lv-lv .navigation__text,
.lt-lt .navigation__text,
[lang^='ru'] .navigation__text,
[lang^='pl'] .navigation__text,
[lang^='hu'] .navigation__text,
[lang^='cs'] .navigation__text,
[lang^='et'] .navigation__text,
[lang^='lv'] .navigation__text,
[lang^='lt'] .navigation__text {
  font-weight: bold;
  font-family: 'Arimo', sans-serif !important; }

.ru-ru .navigation__text strong,
.pl-pl .navigation__text strong,
.hu-hu .navigation__text strong,
.cs-cz .navigation__text strong,
.et-ee .navigation__text strong,
.lv-lv .navigation__text strong,
.lt-lt .navigation__text strong,
[lang^='ru'] .navigation__text strong,
[lang^='pl'] .navigation__text strong,
[lang^='hu'] .navigation__text strong,
[lang^='cs'] .navigation__text strong,
[lang^='et'] .navigation__text strong,
[lang^='lv'] .navigation__text strong,
[lang^='lt'] .navigation__text strong {
  font-weight: bold;
  font-family: 'Arimo', sans-serif !important; }

.ru-ru .navigation__text b,
.pl-pl .navigation__text b,
.hu-hu .navigation__text b,
.cs-cz .navigation__text b,
.et-ee .navigation__text b,
.lv-lv .navigation__text b,
.lt-lt .navigation__text b,
[lang^='ru'] .navigation__text b,
[lang^='pl'] .navigation__text b,
[lang^='hu'] .navigation__text b,
[lang^='cs'] .navigation__text b,
[lang^='et'] .navigation__text b,
[lang^='lv'] .navigation__text b,
[lang^='lt'] .navigation__text b {
  font-weight: bold;
  font-family: 'Arimo', sans-serif !important; }

.navigation__text:hover, .navigation__text.active {
  background-color: rgba(255, 255, 255, 0.1); }

.navigation__text img {
  max-width: 85%; }

.navigation__text--icon {
  width: 20px;
  height: 20px; }

.product-import {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  background-color: #fff;
  height: 100%;
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
  z-index: 4; }

@media (min-width: 768px) {
  .product-import {
    left: 74px;
    width: 780px; } }

.product-import.is-active {
  position: fixed;
  -webkit-transform: translateX(0);
          transform: translateX(0); }

.product-import__title {
  padding: 30px 80px;
  font-family: 'Helvetica Neue LT W05_83 Hv Ex', Helvetica, sans-serif;
  font-size: 14px;
  color: #000; }

.ru-ru .product-import__title,
.pl-pl .product-import__title,
.hu-hu .product-import__title,
.cs-cz .product-import__title,
.et-ee .product-import__title,
.lv-lv .product-import__title,
.lt-lt .product-import__title,
[lang^='ru'] .product-import__title,
[lang^='pl'] .product-import__title,
[lang^='hu'] .product-import__title,
[lang^='cs'] .product-import__title,
[lang^='et'] .product-import__title,
[lang^='lv'] .product-import__title,
[lang^='lt'] .product-import__title {
  font-weight: bold;
  font-family: 'Arimo', sans-serif !important; }

.ru-ru .product-import__title strong,
.pl-pl .product-import__title strong,
.hu-hu .product-import__title strong,
.cs-cz .product-import__title strong,
.et-ee .product-import__title strong,
.lv-lv .product-import__title strong,
.lt-lt .product-import__title strong,
[lang^='ru'] .product-import__title strong,
[lang^='pl'] .product-import__title strong,
[lang^='hu'] .product-import__title strong,
[lang^='cs'] .product-import__title strong,
[lang^='et'] .product-import__title strong,
[lang^='lv'] .product-import__title strong,
[lang^='lt'] .product-import__title strong {
  font-weight: bold;
  font-family: 'Arimo', sans-serif !important; }

.ru-ru .product-import__title b,
.pl-pl .product-import__title b,
.hu-hu .product-import__title b,
.cs-cz .product-import__title b,
.et-ee .product-import__title b,
.lv-lv .product-import__title b,
.lt-lt .product-import__title b,
[lang^='ru'] .product-import__title b,
[lang^='pl'] .product-import__title b,
[lang^='hu'] .product-import__title b,
[lang^='cs'] .product-import__title b,
[lang^='et'] .product-import__title b,
[lang^='lv'] .product-import__title b,
[lang^='lt'] .product-import__title b {
  font-weight: bold;
  font-family: 'Arimo', sans-serif !important; }

.product-import__container {
  overflow-y: auto;
  display: none; }

.is-active .product-import__container {
  display: block;
  height: 100%; }

.product-import__import-container {
  width: 100%;
  height: 85%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center; }

.product-import__import {
  font-family: 'Helvetica Neue LT W05_63 Md Ex', sans-serif;
  font-style: normal;
  font-weight: normal;
  width: 95%;
  height: 100%;
  padding: 10px;
  font-size: 13px;
  border-radius: 10px;
  border: 2px solid #db011c;
  resize: none; }

.ru-ru .product-import__import,
.pl-pl .product-import__import,
.hu-hu .product-import__import,
.cs-cz .product-import__import,
.et-ee .product-import__import,
.lv-lv .product-import__import,
.lt-lt .product-import__import,
[lang^='ru'] .product-import__import,
[lang^='pl'] .product-import__import,
[lang^='hu'] .product-import__import,
[lang^='cs'] .product-import__import,
[lang^='et'] .product-import__import,
[lang^='lv'] .product-import__import,
[lang^='lt'] .product-import__import {
  font-weight: bold;
  font-family: 'Arimo', sans-serif !important; }

.ru-ru .product-import__import strong,
.pl-pl .product-import__import strong,
.hu-hu .product-import__import strong,
.cs-cz .product-import__import strong,
.et-ee .product-import__import strong,
.lv-lv .product-import__import strong,
.lt-lt .product-import__import strong,
[lang^='ru'] .product-import__import strong,
[lang^='pl'] .product-import__import strong,
[lang^='hu'] .product-import__import strong,
[lang^='cs'] .product-import__import strong,
[lang^='et'] .product-import__import strong,
[lang^='lv'] .product-import__import strong,
[lang^='lt'] .product-import__import strong {
  font-weight: bold;
  font-family: 'Arimo', sans-serif !important; }

.ru-ru .product-import__import b,
.pl-pl .product-import__import b,
.hu-hu .product-import__import b,
.cs-cz .product-import__import b,
.et-ee .product-import__import b,
.lv-lv .product-import__import b,
.lt-lt .product-import__import b,
[lang^='ru'] .product-import__import b,
[lang^='pl'] .product-import__import b,
[lang^='hu'] .product-import__import b,
[lang^='cs'] .product-import__import b,
[lang^='et'] .product-import__import b,
[lang^='lv'] .product-import__import b,
[lang^='lt'] .product-import__import b {
  font-weight: bold;
  font-family: 'Arimo', sans-serif !important; }

@media (min-width: 768px) {
  .product-import__import {
    padding: 20px; } }

.product-import__button {
  position: absolute;
  bottom: 0;
  width: 100%; }

.product-import__results {
  max-height: 170px;
  overflow-y: auto;
  margin-bottom: 20px; }

.product-import__results li:before {
  content: '-';
  padding-right: 15px;
  display: inline; }

.product-search {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  background-color: #fff;
  height: 100%;
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
  z-index: 4; }

@media (min-width: 768px) {
  .product-search {
    left: 74px;
    width: 780px; } }

.product-search.is-active {
  position: fixed;
  -webkit-transform: translateX(0);
          transform: translateX(0); }

.product-search__container {
  padding-bottom: 120px;
  display: none; }

.is-active .product-search__container {
  display: block;
  height: 100%; }

.product-search__products-wrapper {
  height: 100%;
  overflow-y: auto; }

.product-search__search-container {
  width: 100%;
  height: 74px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #383739; }

.product-search__search {
  font-family: 'Helvetica Neue LT W05_63 Md Ex', sans-serif;
  font-style: normal;
  font-weight: normal;
  width: 95%;
  height: 49px;
  padding-left: 10px;
  font-size: 13px;
  border-radius: 10px;
  border: none; }

.ru-ru .product-search__search,
.pl-pl .product-search__search,
.hu-hu .product-search__search,
.cs-cz .product-search__search,
.et-ee .product-search__search,
.lv-lv .product-search__search,
.lt-lt .product-search__search,
[lang^='ru'] .product-search__search,
[lang^='pl'] .product-search__search,
[lang^='hu'] .product-search__search,
[lang^='cs'] .product-search__search,
[lang^='et'] .product-search__search,
[lang^='lv'] .product-search__search,
[lang^='lt'] .product-search__search {
  font-weight: bold;
  font-family: 'Arimo', sans-serif !important; }

.ru-ru .product-search__search strong,
.pl-pl .product-search__search strong,
.hu-hu .product-search__search strong,
.cs-cz .product-search__search strong,
.et-ee .product-search__search strong,
.lv-lv .product-search__search strong,
.lt-lt .product-search__search strong,
[lang^='ru'] .product-search__search strong,
[lang^='pl'] .product-search__search strong,
[lang^='hu'] .product-search__search strong,
[lang^='cs'] .product-search__search strong,
[lang^='et'] .product-search__search strong,
[lang^='lv'] .product-search__search strong,
[lang^='lt'] .product-search__search strong {
  font-weight: bold;
  font-family: 'Arimo', sans-serif !important; }

.ru-ru .product-search__search b,
.pl-pl .product-search__search b,
.hu-hu .product-search__search b,
.cs-cz .product-search__search b,
.et-ee .product-search__search b,
.lv-lv .product-search__search b,
.lt-lt .product-search__search b,
[lang^='ru'] .product-search__search b,
[lang^='pl'] .product-search__search b,
[lang^='hu'] .product-search__search b,
[lang^='cs'] .product-search__search b,
[lang^='et'] .product-search__search b,
[lang^='lv'] .product-search__search b,
[lang^='lt'] .product-search__search b {
  font-weight: bold;
  font-family: 'Arimo', sans-serif !important; }

@media (min-width: 768px) {
  .product-search__search {
    padding-left: 50px; } }

.product-search__button {
  position: absolute;
  bottom: 0;
  width: 100%; }

.product-select {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  background-color: #fff;
  height: 100%;
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
  z-index: 4; }

@media (min-width: 768px) {
  .product-select {
    left: 74px;
    width: 992px; } }

.product-select.is-active {
  position: fixed;
  -webkit-transform: translateX(0);
          transform: translateX(0); }

.product-select__close {
  display: none;
  position: absolute;
  top: 0;
  right: -74px;
  width: 74px;
  height: 74px;
  text-indent: -9999px;
  background: #000 url(/static/media/svg--x.07127464.svg) no-repeat center;
  background-size: 22px 22px;
  cursor: pointer; }

.is-active .product-select__close {
  display: block; }

.product-select__container {
  padding-bottom: 120px;
  display: none; }

.is-active .product-select__container {
  display: block;
  height: 100%; }

.product-select .btn {
  cursor: pointer; }

.product-select .btn--collapse {
  min-width: 172px; }

.product-select .btn--collapse span:nth-child(1) {
  display: block; }

[aria-current='false'] .product-select .btn--collapse span:nth-child(1) {
  display: none; }

.product-select .btn--collapse span:nth-child(2) {
  display: none; }

[aria-current='false'] .product-select .btn--collapse span:nth-child(2) {
  display: block; }

.product-select [aria-current='false'] .btn--collapse span:nth-child(1) {
  display: none; }

.product-select [aria-current='false'] .btn--collapse span:nth-child(2) {
  display: block; }

.product-select__category, .product-select__sub-category {
  padding: 15px 45px;
  font-family: 'Helvetica Neue LT W05_83 Hv Ex', Helvetica, sans-serif;
  font-size: 14px;
  color: #383739;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between; }

.ru-ru .product-select__category,
.pl-pl .product-select__category,
.hu-hu .product-select__category,
.cs-cz .product-select__category,
.et-ee .product-select__category,
.lv-lv .product-select__category,
.lt-lt .product-select__category,
[lang^='ru'] .product-select__category,
[lang^='pl'] .product-select__category,
[lang^='hu'] .product-select__category,
[lang^='cs'] .product-select__category,
[lang^='et'] .product-select__category,
[lang^='lv'] .product-select__category,
[lang^='lt'] .product-select__category, .ru-ru .product-select__sub-category,
.pl-pl .product-select__sub-category,
.hu-hu .product-select__sub-category,
.cs-cz .product-select__sub-category,
.et-ee .product-select__sub-category,
.lv-lv .product-select__sub-category,
.lt-lt .product-select__sub-category,
[lang^='ru'] .product-select__sub-category,
[lang^='pl'] .product-select__sub-category,
[lang^='hu'] .product-select__sub-category,
[lang^='cs'] .product-select__sub-category,
[lang^='et'] .product-select__sub-category,
[lang^='lv'] .product-select__sub-category,
[lang^='lt'] .product-select__sub-category {
  font-weight: bold;
  font-family: 'Arimo', sans-serif !important; }

.ru-ru .product-select__category strong,
.pl-pl .product-select__category strong,
.hu-hu .product-select__category strong,
.cs-cz .product-select__category strong,
.et-ee .product-select__category strong,
.lv-lv .product-select__category strong,
.lt-lt .product-select__category strong,
[lang^='ru'] .product-select__category strong,
[lang^='pl'] .product-select__category strong,
[lang^='hu'] .product-select__category strong,
[lang^='cs'] .product-select__category strong,
[lang^='et'] .product-select__category strong,
[lang^='lv'] .product-select__category strong,
[lang^='lt'] .product-select__category strong, .ru-ru .product-select__sub-category strong,
.pl-pl .product-select__sub-category strong,
.hu-hu .product-select__sub-category strong,
.cs-cz .product-select__sub-category strong,
.et-ee .product-select__sub-category strong,
.lv-lv .product-select__sub-category strong,
.lt-lt .product-select__sub-category strong,
[lang^='ru'] .product-select__sub-category strong,
[lang^='pl'] .product-select__sub-category strong,
[lang^='hu'] .product-select__sub-category strong,
[lang^='cs'] .product-select__sub-category strong,
[lang^='et'] .product-select__sub-category strong,
[lang^='lv'] .product-select__sub-category strong,
[lang^='lt'] .product-select__sub-category strong {
  font-weight: bold;
  font-family: 'Arimo', sans-serif !important; }

.ru-ru .product-select__category b,
.pl-pl .product-select__category b,
.hu-hu .product-select__category b,
.cs-cz .product-select__category b,
.et-ee .product-select__category b,
.lv-lv .product-select__category b,
.lt-lt .product-select__category b,
[lang^='ru'] .product-select__category b,
[lang^='pl'] .product-select__category b,
[lang^='hu'] .product-select__category b,
[lang^='cs'] .product-select__category b,
[lang^='et'] .product-select__category b,
[lang^='lv'] .product-select__category b,
[lang^='lt'] .product-select__category b, .ru-ru .product-select__sub-category b,
.pl-pl .product-select__sub-category b,
.hu-hu .product-select__sub-category b,
.cs-cz .product-select__sub-category b,
.et-ee .product-select__sub-category b,
.lv-lv .product-select__sub-category b,
.lt-lt .product-select__sub-category b,
[lang^='ru'] .product-select__sub-category b,
[lang^='pl'] .product-select__sub-category b,
[lang^='hu'] .product-select__sub-category b,
[lang^='cs'] .product-select__sub-category b,
[lang^='et'] .product-select__sub-category b,
[lang^='lv'] .product-select__sub-category b,
[lang^='lt'] .product-select__sub-category b {
  font-weight: bold;
  font-family: 'Arimo', sans-serif !important; }

.product-select__sub-category {
  position: relative;
  z-index: 1; }

.product-select .selected-products__select-all--label {
  color: #000; }

.product-select__select-all {
  padding: 12px 45px;
  font-size: 12px;
  height: 74px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: #fff;
  color: #000;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  -webkit-filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.2));
          filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.2)); }

.product-select__category-title {
  font-family: 'Helvetica Neue LT W05_83 Hv Ex', Helvetica, sans-serif;
  font-size: 18px;
  line-height: 1em; }

.ru-ru .product-select__category-title,
.pl-pl .product-select__category-title,
.hu-hu .product-select__category-title,
.cs-cz .product-select__category-title,
.et-ee .product-select__category-title,
.lv-lv .product-select__category-title,
.lt-lt .product-select__category-title,
[lang^='ru'] .product-select__category-title,
[lang^='pl'] .product-select__category-title,
[lang^='hu'] .product-select__category-title,
[lang^='cs'] .product-select__category-title,
[lang^='et'] .product-select__category-title,
[lang^='lv'] .product-select__category-title,
[lang^='lt'] .product-select__category-title {
  font-weight: bold;
  font-family: 'Arimo', sans-serif !important; }

.ru-ru .product-select__category-title strong,
.pl-pl .product-select__category-title strong,
.hu-hu .product-select__category-title strong,
.cs-cz .product-select__category-title strong,
.et-ee .product-select__category-title strong,
.lv-lv .product-select__category-title strong,
.lt-lt .product-select__category-title strong,
[lang^='ru'] .product-select__category-title strong,
[lang^='pl'] .product-select__category-title strong,
[lang^='hu'] .product-select__category-title strong,
[lang^='cs'] .product-select__category-title strong,
[lang^='et'] .product-select__category-title strong,
[lang^='lv'] .product-select__category-title strong,
[lang^='lt'] .product-select__category-title strong {
  font-weight: bold;
  font-family: 'Arimo', sans-serif !important; }

.ru-ru .product-select__category-title b,
.pl-pl .product-select__category-title b,
.hu-hu .product-select__category-title b,
.cs-cz .product-select__category-title b,
.et-ee .product-select__category-title b,
.lv-lv .product-select__category-title b,
.lt-lt .product-select__category-title b,
[lang^='ru'] .product-select__category-title b,
[lang^='pl'] .product-select__category-title b,
[lang^='hu'] .product-select__category-title b,
[lang^='cs'] .product-select__category-title b,
[lang^='et'] .product-select__category-title b,
[lang^='lv'] .product-select__category-title b,
[lang^='lt'] .product-select__category-title b {
  font-weight: bold;
  font-family: 'Arimo', sans-serif !important; }

.product-select__button {
  position: absolute;
  bottom: 0;
  width: 100%; }

.product-select__products {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin-bottom: 40px;
  justify-content: space-between; }

[aria-current='false'] .product-select__products {
  max-height: 0px;
  margin: 0;
  overflow: hidden; }

.product-select__product-item {
  margin-bottom: 2px;
  width: 100%; }

@media (min-width: 768px) {
  .product-select__product-item--half {
    width: calc(50% - 1px); } }

.product-select__product-item--input {
  position: absolute;
  left: -9999px; }

.product-select__product-item--controls button {
  color: #fff;
  padding: 0px 15px;
  font-family: 'Helvetica Neue LT W05_53 Ext', sans-serif;
  font-stretch: semi-expanded;
  font-style: normal;
  font-weight: 400; }

.product-select__product-item--label {
  padding: 10px 40px 10px 80px;
  font-family: 'Helvetica Neue LT W05_53 Ext', sans-serif;
  font-stretch: semi-expanded;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  position: relative;
  color: #231f20;
  background-color: #f8f8f8;
  cursor: pointer;
  -webkit-transition: background-color 0.2s;
  transition: background-color 0.2s;
  text-transform: uppercase;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.product-select__product-item--label:before {
  content: '';
  position: absolute;
  background-image: url(/static/media/svg--unchecked.56b3dfaa.svg);
  background-size: cover;
  width: 20px;
  height: 20px;
  left: 45px;
  top: 7px; }

.title-layout .product-select__product-item--label:before {
  left: 10px; }

.product-select__product-item--label.hide-checkbox:before {
  display: none; }

.title-layout .product-select__product-item--label {
  padding: 10px 40px 10px 40px; }

.product-select__product-item--arrow {
  width: 30px;
  height: 30px;
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
  margin: -5px auto -8px 10px; }

.accordion-open .product-select__product-item--arrow {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg); }

.product-select__product-item--dark .product-select__product-item--label {
  color: #fff;
  background-color: #383739; }

.product-select__product-item--grey .product-select__product-item--label {
  color: #383739;
  background-color: #e4e4e4;
  font-family: 'Helvetica Neue LT W05_83 Hv Ex', Helvetica, sans-serif;
  font-size: 14px;
  text-transform: none; }

.ru-ru .product-select__product-item--grey .product-select__product-item--label,
.pl-pl .product-select__product-item--grey .product-select__product-item--label,
.hu-hu .product-select__product-item--grey .product-select__product-item--label,
.cs-cz .product-select__product-item--grey .product-select__product-item--label,
.et-ee .product-select__product-item--grey .product-select__product-item--label,
.lv-lv .product-select__product-item--grey .product-select__product-item--label,
.lt-lt .product-select__product-item--grey .product-select__product-item--label,
[lang^='ru'] .product-select__product-item--grey .product-select__product-item--label,
[lang^='pl'] .product-select__product-item--grey .product-select__product-item--label,
[lang^='hu'] .product-select__product-item--grey .product-select__product-item--label,
[lang^='cs'] .product-select__product-item--grey .product-select__product-item--label,
[lang^='et'] .product-select__product-item--grey .product-select__product-item--label,
[lang^='lv'] .product-select__product-item--grey .product-select__product-item--label,
[lang^='lt'] .product-select__product-item--grey .product-select__product-item--label {
  font-weight: bold;
  font-family: 'Arimo', sans-serif !important; }

.ru-ru .product-select__product-item--grey .product-select__product-item--label strong,
.pl-pl .product-select__product-item--grey .product-select__product-item--label strong,
.hu-hu .product-select__product-item--grey .product-select__product-item--label strong,
.cs-cz .product-select__product-item--grey .product-select__product-item--label strong,
.et-ee .product-select__product-item--grey .product-select__product-item--label strong,
.lv-lv .product-select__product-item--grey .product-select__product-item--label strong,
.lt-lt .product-select__product-item--grey .product-select__product-item--label strong,
[lang^='ru'] .product-select__product-item--grey .product-select__product-item--label strong,
[lang^='pl'] .product-select__product-item--grey .product-select__product-item--label strong,
[lang^='hu'] .product-select__product-item--grey .product-select__product-item--label strong,
[lang^='cs'] .product-select__product-item--grey .product-select__product-item--label strong,
[lang^='et'] .product-select__product-item--grey .product-select__product-item--label strong,
[lang^='lv'] .product-select__product-item--grey .product-select__product-item--label strong,
[lang^='lt'] .product-select__product-item--grey .product-select__product-item--label strong {
  font-weight: bold;
  font-family: 'Arimo', sans-serif !important; }

.ru-ru .product-select__product-item--grey .product-select__product-item--label b,
.pl-pl .product-select__product-item--grey .product-select__product-item--label b,
.hu-hu .product-select__product-item--grey .product-select__product-item--label b,
.cs-cz .product-select__product-item--grey .product-select__product-item--label b,
.et-ee .product-select__product-item--grey .product-select__product-item--label b,
.lv-lv .product-select__product-item--grey .product-select__product-item--label b,
.lt-lt .product-select__product-item--grey .product-select__product-item--label b,
[lang^='ru'] .product-select__product-item--grey .product-select__product-item--label b,
[lang^='pl'] .product-select__product-item--grey .product-select__product-item--label b,
[lang^='hu'] .product-select__product-item--grey .product-select__product-item--label b,
[lang^='cs'] .product-select__product-item--grey .product-select__product-item--label b,
[lang^='et'] .product-select__product-item--grey .product-select__product-item--label b,
[lang^='lv'] .product-select__product-item--grey .product-select__product-item--label b,
[lang^='lt'] .product-select__product-item--grey .product-select__product-item--label b {
  font-weight: bold;
  font-family: 'Arimo', sans-serif !important; }

.product-select__product-item input:checked + label {
  background-color: #b5b5b5; }

.product-select__product-item input:checked + label::before {
  background-image: url(/static/media/svg--checked.65e39675.svg); }

.projects .project-item {
  background-color: #fff;
  padding: 20px 40px;
  margin-bottom: 2px;
  position: relative;
  display: block;
  text-decoration: none; }

.projects .project-item--link {
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.projects .project-item--link:hover {
  background-color: #f3f3f3; }

.projects .project-item--disabled {
  opacity: 0.5; }

.projects .project-item--disabled .project-item__controls,
.projects .project-item--disabled .project-item__button,
.projects .project-item--disabled a {
  cursor: not-allowed !important;
  pointer-events: none; }

.projects .project-item a {
  text-decoration: none; }

.projects .project-item .flag {
  display: inline-block;
  margin-right: 12px;
  vertical-align: middle; }

.projects .project-item__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap; }

@media (min-width: 996px) {
  .projects .project-item__container {
    flex-wrap: nowrap; } }

.projects .project-item__content {
  margin-bottom: 20px;
  width: 100%; }

@media (min-width: 996px) {
  .projects .project-item__content {
    width: auto;
    margin-bottom: 0px; } }

.projects .project-item__controls {
  display: flex;
  align-self: flex-start; }

.projects .project-item__title {
  font-family: 'Helvetica Neue LT W05_63 Md Ex', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: #000;
  margin-bottom: 10px; }

.ru-ru .projects .project-item__title,
.pl-pl .projects .project-item__title,
.hu-hu .projects .project-item__title,
.cs-cz .projects .project-item__title,
.et-ee .projects .project-item__title,
.lv-lv .projects .project-item__title,
.lt-lt .projects .project-item__title,
[lang^='ru'] .projects .project-item__title,
[lang^='pl'] .projects .project-item__title,
[lang^='hu'] .projects .project-item__title,
[lang^='cs'] .projects .project-item__title,
[lang^='et'] .projects .project-item__title,
[lang^='lv'] .projects .project-item__title,
[lang^='lt'] .projects .project-item__title {
  font-weight: bold;
  font-family: 'Arimo', sans-serif !important; }

.ru-ru .projects .project-item__title strong,
.pl-pl .projects .project-item__title strong,
.hu-hu .projects .project-item__title strong,
.cs-cz .projects .project-item__title strong,
.et-ee .projects .project-item__title strong,
.lv-lv .projects .project-item__title strong,
.lt-lt .projects .project-item__title strong,
[lang^='ru'] .projects .project-item__title strong,
[lang^='pl'] .projects .project-item__title strong,
[lang^='hu'] .projects .project-item__title strong,
[lang^='cs'] .projects .project-item__title strong,
[lang^='et'] .projects .project-item__title strong,
[lang^='lv'] .projects .project-item__title strong,
[lang^='lt'] .projects .project-item__title strong {
  font-weight: bold;
  font-family: 'Arimo', sans-serif !important; }

.ru-ru .projects .project-item__title b,
.pl-pl .projects .project-item__title b,
.hu-hu .projects .project-item__title b,
.cs-cz .projects .project-item__title b,
.et-ee .projects .project-item__title b,
.lv-lv .projects .project-item__title b,
.lt-lt .projects .project-item__title b,
[lang^='ru'] .projects .project-item__title b,
[lang^='pl'] .projects .project-item__title b,
[lang^='hu'] .projects .project-item__title b,
[lang^='cs'] .projects .project-item__title b,
[lang^='et'] .projects .project-item__title b,
[lang^='lv'] .projects .project-item__title b,
[lang^='lt'] .projects .project-item__title b {
  font-weight: bold;
  font-family: 'Arimo', sans-serif !important; }

.projects .project-item__date {
  font-family: 'Helvetica Neue LT W05_53 Ext', sans-serif;
  font-stretch: semi-expanded;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.3); }

.projects .project-item__description {
  font-family: 'Helvetica Neue LT W05_53 Ext', sans-serif;
  font-stretch: semi-expanded;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #000; }

.projects .project-item__button {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-left: 15px;
  padding: 10px; }

.projects .project-item__button:first-child {
  margin-left: 0; }

.projects .project-item__button:hover {
  background-color: #f1f1f1; }

.projects .project-item__toggle {
  position: absolute;
  left: 50%;
  bottom: 0px; }

.projects .project-item__toggle svg {
  fill: #db011c;
  width: 30px;
  height: 30px; }

.projects .project-item__toggle--down svg {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg); }

.projects .project-item__toggle--up svg {
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg); }

.projects .project-item__add {
  font-family: 'Helvetica Neue LT W05_83 Hv Ex', Helvetica, sans-serif;
  font-size: 11px;
  color: #16c50b; }

.ru-ru .projects .project-item__add,
.pl-pl .projects .project-item__add,
.hu-hu .projects .project-item__add,
.cs-cz .projects .project-item__add,
.et-ee .projects .project-item__add,
.lv-lv .projects .project-item__add,
.lt-lt .projects .project-item__add,
[lang^='ru'] .projects .project-item__add,
[lang^='pl'] .projects .project-item__add,
[lang^='hu'] .projects .project-item__add,
[lang^='cs'] .projects .project-item__add,
[lang^='et'] .projects .project-item__add,
[lang^='lv'] .projects .project-item__add,
[lang^='lt'] .projects .project-item__add {
  font-weight: bold;
  font-family: 'Arimo', sans-serif !important; }

.ru-ru .projects .project-item__add strong,
.pl-pl .projects .project-item__add strong,
.hu-hu .projects .project-item__add strong,
.cs-cz .projects .project-item__add strong,
.et-ee .projects .project-item__add strong,
.lv-lv .projects .project-item__add strong,
.lt-lt .projects .project-item__add strong,
[lang^='ru'] .projects .project-item__add strong,
[lang^='pl'] .projects .project-item__add strong,
[lang^='hu'] .projects .project-item__add strong,
[lang^='cs'] .projects .project-item__add strong,
[lang^='et'] .projects .project-item__add strong,
[lang^='lv'] .projects .project-item__add strong,
[lang^='lt'] .projects .project-item__add strong {
  font-weight: bold;
  font-family: 'Arimo', sans-serif !important; }

.ru-ru .projects .project-item__add b,
.pl-pl .projects .project-item__add b,
.hu-hu .projects .project-item__add b,
.cs-cz .projects .project-item__add b,
.et-ee .projects .project-item__add b,
.lv-lv .projects .project-item__add b,
.lt-lt .projects .project-item__add b,
[lang^='ru'] .projects .project-item__add b,
[lang^='pl'] .projects .project-item__add b,
[lang^='hu'] .projects .project-item__add b,
[lang^='cs'] .projects .project-item__add b,
[lang^='et'] .projects .project-item__add b,
[lang^='lv'] .projects .project-item__add b,
[lang^='lt'] .projects .project-item__add b {
  font-weight: bold;
  font-family: 'Arimo', sans-serif !important; }

.projects .project-item__add span {
  margin-left: 10px; }

.projects .project-item__remove {
  font-family: 'Helvetica Neue LT W05_83 Hv Ex', Helvetica, sans-serif;
  font-size: 11px;
  color: #db011c;
  margin-left: 10px; }

.ru-ru .projects .project-item__remove,
.pl-pl .projects .project-item__remove,
.hu-hu .projects .project-item__remove,
.cs-cz .projects .project-item__remove,
.et-ee .projects .project-item__remove,
.lv-lv .projects .project-item__remove,
.lt-lt .projects .project-item__remove,
[lang^='ru'] .projects .project-item__remove,
[lang^='pl'] .projects .project-item__remove,
[lang^='hu'] .projects .project-item__remove,
[lang^='cs'] .projects .project-item__remove,
[lang^='et'] .projects .project-item__remove,
[lang^='lv'] .projects .project-item__remove,
[lang^='lt'] .projects .project-item__remove {
  font-weight: bold;
  font-family: 'Arimo', sans-serif !important; }

.ru-ru .projects .project-item__remove strong,
.pl-pl .projects .project-item__remove strong,
.hu-hu .projects .project-item__remove strong,
.cs-cz .projects .project-item__remove strong,
.et-ee .projects .project-item__remove strong,
.lv-lv .projects .project-item__remove strong,
.lt-lt .projects .project-item__remove strong,
[lang^='ru'] .projects .project-item__remove strong,
[lang^='pl'] .projects .project-item__remove strong,
[lang^='hu'] .projects .project-item__remove strong,
[lang^='cs'] .projects .project-item__remove strong,
[lang^='et'] .projects .project-item__remove strong,
[lang^='lv'] .projects .project-item__remove strong,
[lang^='lt'] .projects .project-item__remove strong {
  font-weight: bold;
  font-family: 'Arimo', sans-serif !important; }

.ru-ru .projects .project-item__remove b,
.pl-pl .projects .project-item__remove b,
.hu-hu .projects .project-item__remove b,
.cs-cz .projects .project-item__remove b,
.et-ee .projects .project-item__remove b,
.lv-lv .projects .project-item__remove b,
.lt-lt .projects .project-item__remove b,
[lang^='ru'] .projects .project-item__remove b,
[lang^='pl'] .projects .project-item__remove b,
[lang^='hu'] .projects .project-item__remove b,
[lang^='cs'] .projects .project-item__remove b,
[lang^='et'] .projects .project-item__remove b,
[lang^='lv'] .projects .project-item__remove b,
[lang^='lt'] .projects .project-item__remove b {
  font-weight: bold;
  font-family: 'Arimo', sans-serif !important; }

.projects .project-item__remove svg {
  width: 18px;
  height: 18px; }

.projects .project-item__remove span {
  margin-left: 10px; }

.projects .project-item__field {
  margin-bottom: 2px; }

.projects .project-item__field-value {
  color: rgba(0, 0, 0, 0.6);
  margin-left: 7px; }

.projects .no-projects {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 14px;
  gap: 14px;
  background-color: #fff;
  padding: 40px;
  text-align: center; }

.projects .no-projects__title {
  font-size: 20px;
  margin-bottom: 0; }

.projects .no-projects__text {
  font-size: 16px;
  line-height: 26px;
  max-width: 1000px;
  margin-bottom: 0.5em; }

.projects .loading-projects {
  width: 50px;
  height: 50px;
  border: 6px solid #b5b5b5;
  border-top: 6px solid #db011c;
  border-radius: 50%;
  -webkit-animation: spin 1s linear infinite;
          animation: spin 1s linear infinite;
  margin: 100px auto; }

.projects .loading-projects > span {
  display: none; }

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.selected-products__category {
  padding: 15px 40px;
  font-family: 'Helvetica Neue LT W05_83 Hv Ex', Helvetica, sans-serif;
  font-size: 14px;
  color: #383739;
  display: flex;
  align-items: center;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.ru-ru .selected-products__category,
.pl-pl .selected-products__category,
.hu-hu .selected-products__category,
.cs-cz .selected-products__category,
.et-ee .selected-products__category,
.lv-lv .selected-products__category,
.lt-lt .selected-products__category,
[lang^='ru'] .selected-products__category,
[lang^='pl'] .selected-products__category,
[lang^='hu'] .selected-products__category,
[lang^='cs'] .selected-products__category,
[lang^='et'] .selected-products__category,
[lang^='lv'] .selected-products__category,
[lang^='lt'] .selected-products__category {
  font-weight: bold;
  font-family: 'Arimo', sans-serif !important; }

.ru-ru .selected-products__category strong,
.pl-pl .selected-products__category strong,
.hu-hu .selected-products__category strong,
.cs-cz .selected-products__category strong,
.et-ee .selected-products__category strong,
.lv-lv .selected-products__category strong,
.lt-lt .selected-products__category strong,
[lang^='ru'] .selected-products__category strong,
[lang^='pl'] .selected-products__category strong,
[lang^='hu'] .selected-products__category strong,
[lang^='cs'] .selected-products__category strong,
[lang^='et'] .selected-products__category strong,
[lang^='lv'] .selected-products__category strong,
[lang^='lt'] .selected-products__category strong {
  font-weight: bold;
  font-family: 'Arimo', sans-serif !important; }

.ru-ru .selected-products__category b,
.pl-pl .selected-products__category b,
.hu-hu .selected-products__category b,
.cs-cz .selected-products__category b,
.et-ee .selected-products__category b,
.lv-lv .selected-products__category b,
.lt-lt .selected-products__category b,
[lang^='ru'] .selected-products__category b,
[lang^='pl'] .selected-products__category b,
[lang^='hu'] .selected-products__category b,
[lang^='cs'] .selected-products__category b,
[lang^='et'] .selected-products__category b,
[lang^='lv'] .selected-products__category b,
[lang^='lt'] .selected-products__category b {
  font-weight: bold;
  font-family: 'Arimo', sans-serif !important; }

.selected-products__category svg {
  cursor: pointer; }

.selected-products__sub-category {
  font-family: 'Helvetica Neue LT W05_83 Hv Ex', Helvetica, sans-serif;
  padding: 15px 40px;
  font-size: 14px;
  color: #383739;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  position: relative; }

.ru-ru .selected-products__sub-category,
.pl-pl .selected-products__sub-category,
.hu-hu .selected-products__sub-category,
.cs-cz .selected-products__sub-category,
.et-ee .selected-products__sub-category,
.lv-lv .selected-products__sub-category,
.lt-lt .selected-products__sub-category,
[lang^='ru'] .selected-products__sub-category,
[lang^='pl'] .selected-products__sub-category,
[lang^='hu'] .selected-products__sub-category,
[lang^='cs'] .selected-products__sub-category,
[lang^='et'] .selected-products__sub-category,
[lang^='lv'] .selected-products__sub-category,
[lang^='lt'] .selected-products__sub-category {
  font-weight: bold;
  font-family: 'Arimo', sans-serif !important; }

.ru-ru .selected-products__sub-category strong,
.pl-pl .selected-products__sub-category strong,
.hu-hu .selected-products__sub-category strong,
.cs-cz .selected-products__sub-category strong,
.et-ee .selected-products__sub-category strong,
.lv-lv .selected-products__sub-category strong,
.lt-lt .selected-products__sub-category strong,
[lang^='ru'] .selected-products__sub-category strong,
[lang^='pl'] .selected-products__sub-category strong,
[lang^='hu'] .selected-products__sub-category strong,
[lang^='cs'] .selected-products__sub-category strong,
[lang^='et'] .selected-products__sub-category strong,
[lang^='lv'] .selected-products__sub-category strong,
[lang^='lt'] .selected-products__sub-category strong {
  font-weight: bold;
  font-family: 'Arimo', sans-serif !important; }

.ru-ru .selected-products__sub-category b,
.pl-pl .selected-products__sub-category b,
.hu-hu .selected-products__sub-category b,
.cs-cz .selected-products__sub-category b,
.et-ee .selected-products__sub-category b,
.lv-lv .selected-products__sub-category b,
.lt-lt .selected-products__sub-category b,
[lang^='ru'] .selected-products__sub-category b,
[lang^='pl'] .selected-products__sub-category b,
[lang^='hu'] .selected-products__sub-category b,
[lang^='cs'] .selected-products__sub-category b,
[lang^='et'] .selected-products__sub-category b,
[lang^='lv'] .selected-products__sub-category b,
[lang^='lt'] .selected-products__sub-category b {
  font-weight: bold;
  font-family: 'Arimo', sans-serif !important; }

@media (min-width: 768px) {
  .selected-products__sub-category {
    padding: 15px 80px;
    display: block; } }

.selected-products__sub-category:before {
  content: '';
  position: absolute;
  background-image: url(/static/media/svg--unchecked.56b3dfaa.svg);
  background-size: cover;
  width: 20px;
  height: 20px;
  left: 45px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.selected-products__sub-category.active:before {
  background-image: url(/static/media/svg--checked.65e39675.svg); }

.selected-products__sub-category-container {
  margin-bottom: 30px; }

.selected-products__category-container {
  display: none; }

.selected-products__category-container.is-active {
  display: block; }

.selected-products__controls {
  padding: 4px 40px;
  margin-bottom: 14px;
  display: flex;
  align-items: center;
  background: #383739; }

.selected-products__controls .product-select__product-item--controls {
  margin-left: auto;
  color: #fff; }

.selected-products__select-all {
  display: inline-block;
  width: auto;
  margin-right: 15px; }

.selected-products__remove {
  font-family: 'Helvetica Neue LT W05_53 Ext', sans-serif;
  font-stretch: semi-expanded;
  font-style: normal;
  font-weight: 400;
  display: inline-block;
  width: auto;
  color: #fff;
  padding: 10px;
  font-size: 12px; }

.selected-products__select-all--input {
  position: absolute;
  left: -9999px; }

.selected-products__select-all--label {
  font-family: 'Helvetica Neue LT W05_53 Ext', sans-serif;
  font-stretch: semi-expanded;
  font-style: normal;
  font-weight: 400;
  padding: 10px 10px 10px 40px;
  font-size: 12px;
  display: block;
  position: relative;
  color: #fff;
  cursor: pointer;
  -webkit-transition: background-color 0.2s;
  transition: background-color 0.2s;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.selected-products__select-all--label:before {
  content: '';
  position: absolute;
  background-image: url(/static/media/svg--unchecked.56b3dfaa.svg);
  background-size: cover;
  width: 20px;
  height: 20px;
  left: 10px;
  top: 7px; }

.selected-products__select-all input:checked + label {
  background-color: #fff;
  color: #000; }

.selected-products__select-all input:checked + label::before {
  background-image: url(/static/media/svg--checked.65e39675.svg); }

.selected-products__product-item {
  margin-bottom: 1px; }

.selected-products__product-item--input {
  position: absolute;
  left: -9999px; }

.selected-products__product-item--label {
  padding: 10px 80px;
  font-family: 'Helvetica Neue LT W05_53 Ext', sans-serif;
  font-stretch: semi-expanded;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  display: block;
  position: relative;
  color: #fff;
  background-color: #383739;
  cursor: pointer;
  -webkit-transition: background-color 0.2s;
  transition: background-color 0.2s;
  text-transform: uppercase;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.selected-products__product-item--label:before {
  content: '';
  position: absolute;
  background-image: url(/static/media/svg--unchecked.56b3dfaa.svg);
  background-size: cover;
  width: 20px;
  height: 20px;
  left: 45px;
  top: 7px; }

.selected-products__product-item input:checked + label {
  background-color: #b5b5b5; }

.selected-products__product-item input:checked + label::before {
  background-image: url(/static/media/svg--checked.65e39675.svg); }

.selected-products__footer {
  margin-top: 50px;
  display: flex;
  justify-content: center;
  position: -webkit-sticky;
  position: sticky;
  padding: 20px 0;
  bottom: -30px;
  background: #e4e4e4; }

.selected-products__download, .selected-products__print {
  font-family: 'Helvetica Neue LT W05_63 Md Ex', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  margin: 0 10px; }

.ru-ru .selected-products__download,
.pl-pl .selected-products__download,
.hu-hu .selected-products__download,
.cs-cz .selected-products__download,
.et-ee .selected-products__download,
.lv-lv .selected-products__download,
.lt-lt .selected-products__download,
[lang^='ru'] .selected-products__download,
[lang^='pl'] .selected-products__download,
[lang^='hu'] .selected-products__download,
[lang^='cs'] .selected-products__download,
[lang^='et'] .selected-products__download,
[lang^='lv'] .selected-products__download,
[lang^='lt'] .selected-products__download, .ru-ru .selected-products__print,
.pl-pl .selected-products__print,
.hu-hu .selected-products__print,
.cs-cz .selected-products__print,
.et-ee .selected-products__print,
.lv-lv .selected-products__print,
.lt-lt .selected-products__print,
[lang^='ru'] .selected-products__print,
[lang^='pl'] .selected-products__print,
[lang^='hu'] .selected-products__print,
[lang^='cs'] .selected-products__print,
[lang^='et'] .selected-products__print,
[lang^='lv'] .selected-products__print,
[lang^='lt'] .selected-products__print {
  font-weight: bold;
  font-family: 'Arimo', sans-serif !important; }

.ru-ru .selected-products__download strong,
.pl-pl .selected-products__download strong,
.hu-hu .selected-products__download strong,
.cs-cz .selected-products__download strong,
.et-ee .selected-products__download strong,
.lv-lv .selected-products__download strong,
.lt-lt .selected-products__download strong,
[lang^='ru'] .selected-products__download strong,
[lang^='pl'] .selected-products__download strong,
[lang^='hu'] .selected-products__download strong,
[lang^='cs'] .selected-products__download strong,
[lang^='et'] .selected-products__download strong,
[lang^='lv'] .selected-products__download strong,
[lang^='lt'] .selected-products__download strong, .ru-ru .selected-products__print strong,
.pl-pl .selected-products__print strong,
.hu-hu .selected-products__print strong,
.cs-cz .selected-products__print strong,
.et-ee .selected-products__print strong,
.lv-lv .selected-products__print strong,
.lt-lt .selected-products__print strong,
[lang^='ru'] .selected-products__print strong,
[lang^='pl'] .selected-products__print strong,
[lang^='hu'] .selected-products__print strong,
[lang^='cs'] .selected-products__print strong,
[lang^='et'] .selected-products__print strong,
[lang^='lv'] .selected-products__print strong,
[lang^='lt'] .selected-products__print strong {
  font-weight: bold;
  font-family: 'Arimo', sans-serif !important; }

.ru-ru .selected-products__download b,
.pl-pl .selected-products__download b,
.hu-hu .selected-products__download b,
.cs-cz .selected-products__download b,
.et-ee .selected-products__download b,
.lv-lv .selected-products__download b,
.lt-lt .selected-products__download b,
[lang^='ru'] .selected-products__download b,
[lang^='pl'] .selected-products__download b,
[lang^='hu'] .selected-products__download b,
[lang^='cs'] .selected-products__download b,
[lang^='et'] .selected-products__download b,
[lang^='lv'] .selected-products__download b,
[lang^='lt'] .selected-products__download b, .ru-ru .selected-products__print b,
.pl-pl .selected-products__print b,
.hu-hu .selected-products__print b,
.cs-cz .selected-products__print b,
.et-ee .selected-products__print b,
.lv-lv .selected-products__print b,
.lt-lt .selected-products__print b,
[lang^='ru'] .selected-products__print b,
[lang^='pl'] .selected-products__print b,
[lang^='hu'] .selected-products__print b,
[lang^='cs'] .selected-products__print b,
[lang^='et'] .selected-products__print b,
[lang^='lv'] .selected-products__print b,
[lang^='lt'] .selected-products__print b {
  font-weight: bold;
  font-family: 'Arimo', sans-serif !important; }

.tabs {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-bottom: 40px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  justify-content: space-between; }

.tabs .tab {
  font-size: 14px;
  margin: 0px 40px;
  padding: 10px 0px;
  position: relative; }

.tabs .tab.active:after {
  content: '';
  width: 100%;
  position: absolute;
  bottom: -1px;
  height: 1px;
  background-color: #000; }

.loader {
  width: 40px;
  height: 40px;
  margin: 100px auto;
  background-color: #db011c;
  border-radius: 100%;
  -webkit-animation: sk-scaleout 1.6s infinite ease-in-out;
          animation: sk-scaleout 1.6s infinite ease-in-out; }

@keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0); }
  100% {
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
    opacity: 0; } }

.loader-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }

.slide-in-left-enter {
  opacity: 0;
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%); }

.slide-in-left-enter-active {
  opacity: 1;
  -webkit-transform: translateX(0%);
          transform: translateX(0%);
  -webkit-transition: opacity 200ms, -webkit-transform 200ms;
  transition: opacity 200ms, -webkit-transform 200ms;
  transition: opacity 200ms, transform 200ms;
  transition: opacity 200ms, transform 200ms, -webkit-transform 200ms; }

.slide-in-left-exit {
  opacity: 1;
  -webkit-transform: translateX(0%);
          transform: translateX(0%); }

.slide-in-left-exit-active {
  opacity: 0;
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
  -webkit-transition: opacity 200ms, -webkit-transform 200ms;
  transition: opacity 200ms, -webkit-transform 200ms;
  transition: opacity 200ms, transform 200ms;
  transition: opacity 200ms, transform 200ms, -webkit-transform 200ms; }

.slide-in-right-enter {
  opacity: 0;
  -webkit-transform: translateX(100%);
          transform: translateX(100%); }

.slide-in-right-enter-active {
  opacity: 1;
  -webkit-transform: translateX(0%);
          transform: translateX(0%);
  -webkit-transition: opacity 200ms, -webkit-transform 200ms;
  transition: opacity 200ms, -webkit-transform 200ms;
  transition: opacity 200ms, transform 200ms;
  transition: opacity 200ms, transform 200ms, -webkit-transform 200ms; }

.slide-in-right-exit {
  opacity: 1;
  -webkit-transform: translateX(0%);
          transform: translateX(0%); }

.slide-in-right-exit-active {
  opacity: 0;
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
  -webkit-transition: opacity 200ms, -webkit-transform 200ms;
  transition: opacity 200ms, -webkit-transform 200ms;
  transition: opacity 200ms, transform 200ms;
  transition: opacity 200ms, transform 200ms, -webkit-transform 200ms; }

.slide-in-down-enter {
  opacity: 0;
  -webkit-transform: translateY(-100%);
          transform: translateY(-100%); }

.slide-in-down-enter-active {
  opacity: 1;
  -webkit-transform: translateY(0%);
          transform: translateY(0%);
  -webkit-transition: opacity 200ms 200ms, -webkit-transform 200ms 200ms;
  transition: opacity 200ms 200ms, -webkit-transform 200ms 200ms;
  transition: opacity 200ms 200ms, transform 200ms 200ms;
  transition: opacity 200ms 200ms, transform 200ms 200ms, -webkit-transform 200ms 200ms; }

.slide-in-down-exit {
  opacity: 1;
  -webkit-transform: translateY(0%);
          transform: translateY(0%); }

.slide-in-down-exit-active {
  opacity: 0;
  -webkit-transition: opacity 200ms 200ms;
  transition: opacity 200ms 200ms; }

.fade-in-enter {
  opacity: 0; }

.fade-in-enter-active {
  opacity: 1;
  -webkit-transition: opacity 200ms;
  transition: opacity 200ms; }

.fade-in-exit {
  opacity: 0; }

.fade-in-exit-active {
  opacity: 0; }

.fade-enter {
  opacity: 0; }

.fade-enter-active {
  opacity: 1;
  -webkit-transition: opacity 300ms ease-in;
  transition: opacity 300ms ease-in; }

.fade-exit {
  opacity: 1; }

.fade-exit-active {
  opacity: 0;
  -webkit-transition: opacity 300ms ease-in;
  transition: opacity 300ms ease-in; }

.indication-message {
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; }

.indication-message p {
  font-family: 'Helvetica Neue LT W05_83 Hv Ex', Helvetica, sans-serif;
  font-size: 14px;
  color: #383739; }

.ru-ru .indication-message p,
.pl-pl .indication-message p,
.hu-hu .indication-message p,
.cs-cz .indication-message p,
.et-ee .indication-message p,
.lv-lv .indication-message p,
.lt-lt .indication-message p,
[lang^='ru'] .indication-message p,
[lang^='pl'] .indication-message p,
[lang^='hu'] .indication-message p,
[lang^='cs'] .indication-message p,
[lang^='et'] .indication-message p,
[lang^='lv'] .indication-message p,
[lang^='lt'] .indication-message p {
  font-weight: bold;
  font-family: 'Arimo', sans-serif !important; }

.ru-ru .indication-message p strong,
.pl-pl .indication-message p strong,
.hu-hu .indication-message p strong,
.cs-cz .indication-message p strong,
.et-ee .indication-message p strong,
.lv-lv .indication-message p strong,
.lt-lt .indication-message p strong,
[lang^='ru'] .indication-message p strong,
[lang^='pl'] .indication-message p strong,
[lang^='hu'] .indication-message p strong,
[lang^='cs'] .indication-message p strong,
[lang^='et'] .indication-message p strong,
[lang^='lv'] .indication-message p strong,
[lang^='lt'] .indication-message p strong {
  font-weight: bold;
  font-family: 'Arimo', sans-serif !important; }

.ru-ru .indication-message p b,
.pl-pl .indication-message p b,
.hu-hu .indication-message p b,
.cs-cz .indication-message p b,
.et-ee .indication-message p b,
.lv-lv .indication-message p b,
.lt-lt .indication-message p b,
[lang^='ru'] .indication-message p b,
[lang^='pl'] .indication-message p b,
[lang^='hu'] .indication-message p b,
[lang^='cs'] .indication-message p b,
[lang^='et'] .indication-message p b,
[lang^='lv'] .indication-message p b,
[lang^='lt'] .indication-message p b {
  font-weight: bold;
  font-family: 'Arimo', sans-serif !important; }

.indication-message .btn {
  margin-top: 10px; }

.flag {
  display: block;
  height: 25px;
  background: url(/static/media/flags.49531e84.png) 0 0 no-repeat;
  background-size: 36px;
  width: 36px;
  min-width: 36px; }

.flag.cs-CZ {
  background-position: 0 -30px; }

.flag.da-DK {
  background-position: 0 -60px; }

.flag.de-DE {
  background-position: 0 -90px; }

.flag.en-GB {
  background-position: 0 -120px; }

.flag.es-ES {
  background-position: 0 -150px; }

.flag.fi-FI {
  background-position: 0 -180px; }

.flag.fr-FR {
  background-position: 0 -210px; }

.flag.hu-HU {
  background-position: 0 -240px; }

.flag.it-IT {
  background-position: 0 -270px; }

.flag.nl-NL {
  background-position: 0 -300px; }

.flag.nn-NO {
  background-position: 0 -330px; }

.flag.pl-PL {
  background-position: 0 -360px; }

.flag.ru-RU {
  background-position: 0 -390px; }

.flag.sv-SE {
  background-position: 0 -420px; }

.flag.be-BE, .flag.nl-BE, .flag.fr-BE {
  background-position: 0 -450px; }

.flag.lt-LT {
  background-position: 0 -480px; }

.flag.lv-LV {
  background-position: 0 -510px; }

.flag.et-EE {
  background-position: 0 -540px; }

.flag.de-CH {
  background-position: 0 -569px; }

.flag.ar-AE {
  background-position: 0 -599px; }

.flag.en-ZA {
  background-position: 0 -629px; }

.flag.pt-PT {
  background-position: 0 -659px; }

.flag.sk-SK {
  background-position: 0px -688px; }

.flag.tr-TR {
  background-position: 0 -721px; }

.flag.de-AT {
  background-position: 0 -750px; }

.legacy-warning {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center; }

.legacy-warning p {
  font-size: 16px; }

.legacy-warning__inner {
  position: relative;
  z-index: 1;
  text-align: center;
  padding: 20px; }

.legacy-warning a {
  color: #db011c; }

.table__wrapper {
  background-color: #fff;
  padding: 20px 40px; }

.table__container {
  margin-bottom: 30px; }

.table__sorted {
  color: #db011c; }

.table__sort-icon {
  position: relative;
  padding-left: 5px; }

.table__sort-icon svg {
  fill: #db011c;
  position: absolute;
  top: 1px;
  width: 15px;
  height: 15px; }

.table__date-filters {
  display: flex;
  margin-bottom: 15px; }

.table__date-filters--group {
  display: flex;
  align-items: center;
  margin-right: 15px; }

.table__date-filters--group label {
  font-family: 'Helvetica Neue LT W05_83 Hv Ex', Helvetica, sans-serif;
  padding-right: 10px; }

.ru-ru .table__date-filters--group label,
.pl-pl .table__date-filters--group label,
.hu-hu .table__date-filters--group label,
.cs-cz .table__date-filters--group label,
.et-ee .table__date-filters--group label,
.lv-lv .table__date-filters--group label,
.lt-lt .table__date-filters--group label,
[lang^='ru'] .table__date-filters--group label,
[lang^='pl'] .table__date-filters--group label,
[lang^='hu'] .table__date-filters--group label,
[lang^='cs'] .table__date-filters--group label,
[lang^='et'] .table__date-filters--group label,
[lang^='lv'] .table__date-filters--group label,
[lang^='lt'] .table__date-filters--group label {
  font-weight: bold;
  font-family: 'Arimo', sans-serif !important; }

.ru-ru .table__date-filters--group label strong,
.pl-pl .table__date-filters--group label strong,
.hu-hu .table__date-filters--group label strong,
.cs-cz .table__date-filters--group label strong,
.et-ee .table__date-filters--group label strong,
.lv-lv .table__date-filters--group label strong,
.lt-lt .table__date-filters--group label strong,
[lang^='ru'] .table__date-filters--group label strong,
[lang^='pl'] .table__date-filters--group label strong,
[lang^='hu'] .table__date-filters--group label strong,
[lang^='cs'] .table__date-filters--group label strong,
[lang^='et'] .table__date-filters--group label strong,
[lang^='lv'] .table__date-filters--group label strong,
[lang^='lt'] .table__date-filters--group label strong {
  font-weight: bold;
  font-family: 'Arimo', sans-serif !important; }

.ru-ru .table__date-filters--group label b,
.pl-pl .table__date-filters--group label b,
.hu-hu .table__date-filters--group label b,
.cs-cz .table__date-filters--group label b,
.et-ee .table__date-filters--group label b,
.lv-lv .table__date-filters--group label b,
.lt-lt .table__date-filters--group label b,
[lang^='ru'] .table__date-filters--group label b,
[lang^='pl'] .table__date-filters--group label b,
[lang^='hu'] .table__date-filters--group label b,
[lang^='cs'] .table__date-filters--group label b,
[lang^='et'] .table__date-filters--group label b,
[lang^='lv'] .table__date-filters--group label b,
[lang^='lt'] .table__date-filters--group label b {
  font-weight: bold;
  font-family: 'Arimo', sans-serif !important; }

.table__date-filters--group input {
  border: 1px solid black !important;
  padding: 5px; }

.table__date-filters--validation {
  display: flex;
  align-items: center;
  margin-bottom: 0;
  color: #a90000;
  font-weight: 600; }

.table {
  width: 100%;
  border-collapse: collapse; }

.table thead tr th {
  font-family: 'Helvetica Neue LT W05_83 Hv Ex', Helvetica, sans-serif;
  text-align: start;
  font-size: 13px;
  padding-bottom: 10px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.ru-ru .table thead tr th,
.pl-pl .table thead tr th,
.hu-hu .table thead tr th,
.cs-cz .table thead tr th,
.et-ee .table thead tr th,
.lv-lv .table thead tr th,
.lt-lt .table thead tr th,
[lang^='ru'] .table thead tr th,
[lang^='pl'] .table thead tr th,
[lang^='hu'] .table thead tr th,
[lang^='cs'] .table thead tr th,
[lang^='et'] .table thead tr th,
[lang^='lv'] .table thead tr th,
[lang^='lt'] .table thead tr th {
  font-weight: bold;
  font-family: 'Arimo', sans-serif !important; }

.ru-ru .table thead tr th strong,
.pl-pl .table thead tr th strong,
.hu-hu .table thead tr th strong,
.cs-cz .table thead tr th strong,
.et-ee .table thead tr th strong,
.lv-lv .table thead tr th strong,
.lt-lt .table thead tr th strong,
[lang^='ru'] .table thead tr th strong,
[lang^='pl'] .table thead tr th strong,
[lang^='hu'] .table thead tr th strong,
[lang^='cs'] .table thead tr th strong,
[lang^='et'] .table thead tr th strong,
[lang^='lv'] .table thead tr th strong,
[lang^='lt'] .table thead tr th strong {
  font-weight: bold;
  font-family: 'Arimo', sans-serif !important; }

.ru-ru .table thead tr th b,
.pl-pl .table thead tr th b,
.hu-hu .table thead tr th b,
.cs-cz .table thead tr th b,
.et-ee .table thead tr th b,
.lv-lv .table thead tr th b,
.lt-lt .table thead tr th b,
[lang^='ru'] .table thead tr th b,
[lang^='pl'] .table thead tr th b,
[lang^='hu'] .table thead tr th b,
[lang^='cs'] .table thead tr th b,
[lang^='et'] .table thead tr th b,
[lang^='lv'] .table thead tr th b,
[lang^='lt'] .table thead tr th b {
  font-weight: bold;
  font-family: 'Arimo', sans-serif !important; }

.table tbody tr {
  border-bottom: 1px solid #383739; }

.table tbody tr.region {
  background-color: darkgray;
  font-weight: bold; }

.table tbody tr td {
  padding: 10px 0px 5px 0px; }

.table__footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px; }

.table__pagination {
  display: flex;
  align-items: center; }

.table__pagination--arrows {
  padding-right: 20px;
  display: flex; }

.table__pagination--arrows .table__pagination--icon {
  display: flex;
  align-items: center;
  font-size: 20px; }

.table__pagination--arrows .table__pagination--icon:disabled {
  cursor: not-allowed; }

.table__pagination--page {
  display: flex;
  padding-right: 20px;
  margin-bottom: 3px; }

.table__pagination--page p {
  padding-right: 5px;
  margin-bottom: 0; }

